<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <!-- <b-icon
          style="cursor: pointer; margin-top: 10px; margin-left: 20px"
          pack="fas"
          icon="arrow-left"
          size="is-small"
        >
        </b-icon> -->

        <div class="columns">
          <div class="column is-1">
            <b-button
              icon-left="arrow-left"
              icon-pack="fas"
              type="is-info is-light"
              @click="$router.go(-1)"
              expanded
              >{{ i18n.goBack[iso] }}</b-button
            >
          </div>
        </div>

        <!-- <p class="subtitle">Hero subtitle</p> -->
        <!-- <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <b-button
            style="position: relative; bottom: 5%"
            type="is-link is-light"
            icon-left="arrow-left"
            icon-pack="fas"
          >
            {{ i18n.hide[iso] }}
          </b-button>
        </div> -->
      </div>
    </section>

    <section style="margin-top: -90px">
      <div class="card article">
        <div class="card-content">
          <div class="media">
            <div class="media-center">
              <img
                src="img/profile.png"
                class="author-image"
                alt="Placeholder image"
              />
            </div>
            <div class="media-content has-text-centered">
              <p class="title article-title">
                <b>{{ member.Nombre }} {{ member.ApellidoPaterno }}</b
                ><small
                  ><span v-if="coOwner.Nombre && coOwner.Nombre !== ''"
                    >, {{ coOwner.Nombre }} {{ coOwner.ApellidoPaterno }}</span
                  ></small
                >
              </p>
              <p class="subtitle is-6 article-subtitle">
                <b-tag
                  >LoginID: <b>{{ contract.LoginID }}</b></b-tag
                >
                <b-tag style="margin-left: 10px"
                  >{{ i18n.contract[iso] }}: <b>{{ contract.Numero }}</b></b-tag
                >

                <!-- <b-tag style="margin-left: 10px"
                  >{{ i18n.coOwner[iso] }}:
                  <b
                    >{{ coOwner.Nombre }} {{ coOwner.ApellidoPaterno }}</b
                  ></b-tag
                > -->
                <b-tag style="margin-left: 10px"
                  >Club: <b>{{ club.Nombre }}</b></b-tag
                >
                <b-tag
                  style="margin-left: 10px"
                  v-for="item in infoContract"
                  v-if="item.key === 'FechaRenovacion'"
                  :type="isExpired ? 'is-danger' : 'is-success'"
                >
                  {{ i18n.subscription[iso] }}:
                  <b v-if="item.type === 'date'">{{
                    item.isParse
                      ? item.parse(contract[item.key], contract)
                      : contract[item.key]
                  }}</b></b-tag
                >
                <b-tag style="margin-left: 10px" type="is-info is-light"
                  >Bitrix24: <b>{{ bitrix24 || i18n.notFound[iso] }}</b></b-tag
                >
              </p>
            </div>
          </div>

          <b-tabs
            v-model="activeTab"
            position="is-centered"
            type="is-boxed"
            @input="actionTab"
          >
            <!-- Pestaña de Detalles -->
            <b-tab-item>
              <template #header>
                <b-icon icon="user" pack="fas" type="is-info"></b-icon>
                <b>{{ i18n.details[iso] }}</b>
              </template>
              <!-- Titular -->
              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="content">
                    <h3>{{ i18n.owner[iso] }}</h3>
                  </div>
                </div>

                <div :class="`column is-${m.size}`" v-for="m in infoMembers">
                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.isInfo"
                  >
                    <b-input
                      v-model="member[m.key]"
                      type="text"
                      expanded
                      :disabled="true"
                    >
                    </b-input>
                  </b-field>

                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.type === 'text' && m.isInfo === false"
                  >
                    <b-input
                      v-model="member[m.key]"
                      type="text"
                      icon-right="times"
                      icon-right-clickable
                      expanded
                      :disabled="!isEdit"
                    >
                    </b-input>
                  </b-field>

                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.type === 'date' && m.isInfo === false"
                  >
                    <b-datepicker
                      v-model="member[m.key]"
                      :locale="iso"
                      :disabled="!isEdit"
                    >
                    </b-datepicker>
                  </b-field>

                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.type === 'select' && m.isInfo === false"
                  >
                    <b-select
                      v-model="member[m.key]"
                      expanded
                      :disabled="!isEdit"
                    >
                      <option
                        v-if="!m.optionsIsCatalogue"
                        v-for="cc in m.options"
                        :value="cc[m.valueOption]"
                      >
                        {{ m.showOption(cc) }}
                      </option>
                      <option
                        v-if="m.optionsIsCatalogue"
                        v-for="cc in m.withFilters
                          ? m.optionsFilters(catalogues[m.options])
                          : catalogues[m.options]"
                        :value="cc[m.valueOption]"
                      >
                        {{ m.showOption(cc) }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>

              <div class="columns is-multiline">
                <div
                  :class="`column is-${
                    (isEdit && !contract.FechaActivacion) ||
                    (isEdit && contract.FechaActivacion) ||
                    (!isEdit && !contract.FechaActivacion)
                      ? 8
                      : 10
                  }`"
                ></div>

                <div
                  class="column is-2"
                  v-if="
                    !contract.FechaActivacion &&
                    !isEdit &&
                    contract.FechaProcesable
                  "
                >
                  <b-button
                    :icon-left="
                      contract.EstadoMembresia ? 'check-circle' : 'check'
                    "
                    icon-pack="fas"
                    :type="contract.EstadoMembresia ? 'is-light' : 'is-primary'"
                    expanded
                    @click="activateMembership"
                    :loading="loadingButtonEditStatusMembership"
                    >{{ i18n.activate[iso] }}</b-button
                  >
                </div>

                <div class="column is-2" v-if="isEdit">
                  <b-button
                    icon-left="times"
                    icon-pack="fas"
                    type="is-danger"
                    expanded
                    @click="cancelEdit"
                    >{{ i18n.cancel[iso] }}</b-button
                  >
                </div>

                <div class="column is-2">
                  <b-button
                    icon-left="save"
                    icon-pack="fas"
                    type="is-success"
                    expanded
                    @click="editProfile"
                    v-if="isEdit"
                    >{{ i18n.save[iso] }}</b-button
                  >
                  <b-button
                    icon-left="edit"
                    icon-pack="fas"
                    type="is-info"
                    expanded
                    @click="allowEdit"
                    v-else
                    >{{ i18n.edit[iso] }}</b-button
                  >
                </div>
              </div>

              <!-- CoTitular -->
              <div class="columns is-multiline" v-if="coMemberId">
                <div class="column is-12">
                  <div class="content">
                    <h3>{{ i18n.coOwner[iso] }}</h3>
                  </div>
                </div>

                <div
                  :class="`column is-${m.size}`"
                  v-for="m in infoCoMembers()"
                >
                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.isInfo"
                  >
                    <b-input
                      v-model="coOwner[m.key]"
                      type="text"
                      expanded
                      :disabled="true"
                    >
                    </b-input>
                  </b-field>

                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.type === 'text' && m.isInfo === false"
                  >
                    <b-input
                      v-model="coOwner[m.key]"
                      type="text"
                      icon-right="times"
                      icon-right-clickable
                      expanded
                      :disabled="!isCoEdit"
                    >
                    </b-input>
                  </b-field>

                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.type === 'date' && m.isInfo === false"
                  >
                    <b-datepicker
                      v-model="coOwner[m.key]"
                      :locale="iso"
                      :disabled="!isCoEdit"
                    >
                    </b-datepicker>
                  </b-field>

                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.type === 'select' && m.isInfo === false"
                  >
                    <b-select
                      v-model="coOwner[m.key]"
                      expanded
                      :disabled="!isCoEdit"
                    >
                      <option
                        v-if="!m.optionsIsCatalogue"
                        v-for="cc in m.options"
                        :value="cc[m.valueOption]"
                      >
                        {{ m.showOption(cc) }}
                      </option>
                      <option
                        v-if="m.optionsIsCatalogue"
                        v-for="cc in catalogues[m.options]"
                        :value="cc[m.valueOption]"
                      >
                        {{ m.showOption(cc) }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>

              <div class="columns is-multiline" v-if="coMemberId">
                <div :class="`column is-${isCoEdit ? 8 : 10}`"></div>
                <div class="column is-2" v-if="isCoEdit">
                  <b-button
                    icon-left="times"
                    icon-pack="fas"
                    type="is-danger"
                    expanded
                    @click="cancelCoEdit"
                    >{{ i18n.cancel[iso] }}</b-button
                  >
                </div>
                <div class="column is-2">
                  <b-button
                    icon-left="save"
                    icon-pack="fas"
                    type="is-success"
                    expanded
                    @click="editCoProfile"
                    v-if="isCoEdit"
                    >{{ i18n.save[iso] }}</b-button
                  >
                  <b-button
                    icon-left="edit"
                    icon-pack="fas"
                    type="is-info"
                    expanded
                    @click="allowCoEdit"
                    v-else
                    >{{ i18n.edit[iso] }}</b-button
                  >
                </div>
              </div>

              <hr />

              <div class="columns is-multiline">
                <div class="column is-2">
                  <div class="content">
                    <h3>{{ i18n.fraf[iso] }}</h3>
                  </div>
                </div>
                <div class="column is-1" v-if="beneficiaries.length < 5">
                  <b-button
                    icon-left="user-plus"
                    icon-pack="fas"
                    type="is-info"
                    @click="
                      beneficiaries = [
                        ...beneficiaries,
                        {
                          Nombre: null,
                          ApellidoPaterno: null,
                          Parentesco: null,
                          edit: true,
                        },
                      ]
                    "
                    expanded
                  ></b-button>
                </div>
              </div>
              <div
                class="columns is-multiline"
                v-if="beneficiaries && beneficiaries.length > 0"
                v-for="(ff, ffidx) in beneficiaries"
              >
                <div :class="`column is-3`">
                  <b-field
                    label-position="on-border"
                    :label="`${i18n.name[iso]}`"
                  >
                    <b-input
                      v-model="beneficiaries[ffidx].Nombre"
                      type="text"
                      icon-right="times"
                      icon-right-clickable
                      expanded
                      :disabled="!ff.edit"
                    >
                    </b-input>
                  </b-field>
                </div>
                <div :class="`column is-3`">
                  <b-field
                    label-position="on-border"
                    :label="`${i18n.lastname[iso]}`"
                  >
                    <b-input
                      v-model="beneficiaries[ffidx].ApellidoPaterno"
                      type="text"
                      icon-right="times"
                      icon-right-clickable
                      expanded
                      :disabled="!ff.edit"
                    >
                    </b-input>
                  </b-field>
                </div>
                <div :class="`column is-3`">
                  <b-field
                    label-position="on-border"
                    :label="`${i18n.relationship[iso]}`"
                  >
                    <b-select
                      v-model="beneficiaries[ffidx].Parentesco"
                      expanded
                      :disabled="!ff.edit"
                    >
                      <option
                        v-for="cc in catalogues.CatParentescos"
                        :value="cc.Id"
                      >
                        {{ iso === "es" ? cc.Nombre : cc.NombreEn }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div :class="`column is-2`">
                  <b-button
                    icon-left="user-edit"
                    icon-pack="fas"
                    :type="ff.edit ? 'is-success' : 'is-info'"
                    expanded
                    :loading="ff.loading"
                    @click="!ff._id ? createFF(ffidx) : editFF(ff._id, ffidx)"
                    >{{ ff.edit ? i18n.save[iso] : "" }}</b-button
                  >
                </div>
                <div :class="`column is-1`" v-if="ff.edit && ff._id">
                  <b-button
                    icon-left="times"
                    icon-pack="fas"
                    type="is-danger"
                    expanded
                    @click="cancelEditFF(ffidx)"
                  ></b-button>
                </div>
              </div>
            </b-tab-item>
            <!-- Fin de  la Pestaña de Detalles -->

            <!-- Pestaña de Contrato -->
            <b-tab-item>
              <template #header>
                <b-icon icon="file-contract" pack="fas" type="is-info"></b-icon>
                <b>{{ i18n.contract[iso] }}</b>
              </template>

              <!-- Botones superiores -->
              <div class="columns">
                <div class="column is-3"></div>
              </div>

              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="content">
                    <h3>{{ i18n.contract[iso] }}</h3>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="row-content">
                    <div
                      v-for="item in infoContract"
                      :key="item.key"
                      v-if="item.section === 'contrat'"
                    >
                      <b-field
                        label-position="on-border"
                        :label="`${i18n[item.fieldIso][iso]}`"
                        v-if="item.type === 'select' && item.isInfo === false"
                      >
                        <b-select
                          v-model="contract[item.key]"
                          @input="openModal(item.key)"
                        >
                          <option
                            v-if="!item.optionsIsCatalogue"
                            v-for="cc in catalogues[item.options]"
                            :value="cc[item.valueOption]"
                            :key="cc[item.valueOption]"
                          >
                            {{ item.showOption(cc) }}
                          </option>
                          <option
                            v-if="item.optionsIsCatalogue"
                            v-for="cc in catalogues[item.options]"
                            :value="cc[item.valueOption]"
                            :key="cc[item.valueOption]"
                          >
                            {{ item.showOption(cc) }}
                          </option>
                        </b-select>
                      </b-field>

                      <b-field
                        label-position="on-border"
                        :label="`${i18n[item.fieldIso][iso]}`"
                        v-if="item.type === 'text' && item.isInfo === false"
                      >
                        <b-input
                          :value="
                            item.isParse
                              ? item.parse(contract[item.key], contract)
                              : contract[item.key]
                          "
                          disabled
                        >
                        </b-input>
                      </b-field>
                    </div>
                  </div>
                </div>

                

                <div class="column is-12" v-if="travelVoucher.length > 0">
                  <div class="content">
                    <h3>{{ i18n.incentives[iso] }}</h3>
                  </div>
                  <div>
                    <div class="row-content">
                      <div
                        class="row-content-benef"
                        v-for="(travelVoucher, index) in travelVoucher"
                        :key="travelVoucher._id"
                        style="gap: 1em"
                      >
                        <div v-for="item in infoVoucher" :key="item.key">
                          <b-field
                            label-position="on-border"
                            :label="`${i18n[item.fieldIso][iso]}`"
                            v-if="item.key === 'Monto'"
                          >
                            <b-input
                              type="text"
                              icon-right="times"
                              icon-right-clickable
                              expanded
                              :value="'$ ' + travelVoucher[item.key] + ' USD'"
                              disabled
                            />
                          </b-field>
                          <b-field
                            label-position="on-border"
                            :label="`${i18n[item.fieldIso][iso]}`"
                            v-else
                          >
                            <b-input
                              type="text"
                              icon-right="times"
                              icon-right-clickable
                              expanded
                              :value="travelVoucher[item.key]"
                              disabled
                            />
                          </b-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="column is-12">
                  <div class="content">
                    <h3>{{ i18n.beneficiaries[iso] }}</h3>
                  </div>

                  <div
                    class="row-content-benef"
                    v-for="(beneficiaries, index) in beneficiaries"
                    :key="beneficiaries._id"
                  >
                    <div
                      class="column is-half"
                      v-for="item in infoBeneficiares"
                      :key="item.key"
                      label-position="on-border"
                      v-if="!item.isInfo"
                    >
                      <b-field
                        :label="`${i18n[item.fieldIso][iso]} ${
                          item.required ? '*' : ''
                        }`"
                        v-if="item.type === 'text'"
                      >
                        <b-input
                          v-model="beneficiaries[item.key]"
                          type="text"
                          icon-right="times"
                          icon-right-clickable
                          expanded
                          :disabled="!isEdit"
                        ></b-input>
                      </b-field>
                      <b-field
                        :label="`${i18n[item.fieldIso][iso]} ${
                          item.required ? '*' : ''
                        }`"
                        v-if="item.type === 'select' && item.isInfo === false"
                      >
                        <b-select
                          v-model="beneficiaries[item.key]"
                          :disabled="!isEdit"
                        >
                          <option
                            v-if="!item.optionsIsCatalogue"
                            v-for="cc in catalogues[item.options]"
                            :value="cc[item.valueOption]"
                            :key="cc[item.valueOption]"
                          >
                            {{ item.showOption(cc) }}
                          </option>
                          <option
                            v-if="item.optionsIsCatalogue"
                            v-for="cc in catalogues[item.options]"
                            :value="cc[item.valueOption]"
                            :key="cc[item.valueOption]"
                          >
                            {{ item.showOption(cc) }}
                          </option>
                        </b-select>
                      </b-field>
                    </div>
                  </div>

                  <div :class="`column is-${isEdit ? 12 : 12}`"></div>

                  <div class="row-friendsfamily is-2" v-if="isEdit">
                    <b-button
                      style="width: 200px"
                      icon-left="save"
                      icon-pack="fas"
                      type="is-success"
                      expanded
                      @click="editFriendsAndFamily"
                      >{{ i18n.save[iso] }}</b-button
                    >
                    <b-button
                      style="width: 200px"
                      icon-left="times"
                      icon-pack="fas"
                      type="is-danger"
                      expanded
                      @click="cancelEdit"
                      >{{ i18n.cancel[iso] }}</b-button
                    >
                  </div>

                  <div class="row-friendsfamily is-2" v-else>
                    <b-button
                      style="width: 40px"
                      class="btns"
                      icon-left="edit"
                      icon-pack="fas"
                      type="is-info"
                      expanded
                      @click="allowEdit"
                    ></b-button>
                    <b-button
                      style="width: 40px"
                      class="btns"
                      icon-left="plus"
                      icon-pack="fas"
                      type="is-info"
                      expanded
                      :disabled="isDisabled()"
                      @click="openModal('formulario')"
                    ></b-button>
                  </div>
                </div> -->
              </div>

              <b-modal :active.sync="showModal" scroll="keep" has-modal-card>
                <div
                  v-if="this.modalType === 'idioma'"
                  class="card"
                  style="width: 400px"
                >
                  <header class="modal-card-head">
                    <p class="modal-card-title">
                      {{ i18n.changeLenguage[iso] }}
                    </p>
                    <button class="delete" @click="showModal = false"></button>
                  </header>
                  <section class="modal-card-body">
                    <p>{{ i18n.changeIso[iso] }}</p>
                  </section>
                  <footer class="modal-card-foot">
                    <button
                      class="button is-primary"
                      @click="handleButtonStatus(newStatus)"
                    >
                      {{ i18n.accept[iso] }}
                    </button>
                    <button class="button" @click="showModal = false">
                      {{ i18n.cancel[iso] }}
                    </button>
                  </footer>
                </div>

                <div
                  v-if="this.modalType === 'contrato'"
                  class="card"
                  style="width: 500px"
                >
                  <header class="modal-card-head">
                    <p class="modal-card-title">
                      {{ i18n.changeStatusContract[iso] }}
                    </p>
                    <button class="delete" @click="showModal = false"></button>
                  </header>
                  <section class="modal-card-body">
                    <p>{{ i18n.confirmChangeStatusContract[iso] }}</p>
                  </section>
                  <footer class="modal-card-foot">
                    <button
                      class="button is-primary"
                      @click="handleButtonStatus(newStatus)"
                    >
                      {{ i18n.accept[iso] }}
                    </button>
                    <button class="button" @click="showModal = false">
                      {{ i18n.cancel[iso] }}
                    </button>
                  </footer>
                </div>

                <div
                  v-else-if="this.modalType === 'formulario'"
                  class="card"
                  style="width: 500px"
                >
                  <header class="modal-card-head">
                    <p class="modal-card-title">{{ i18n.form[iso] }}</p>
                    <button class="delete" @click="showModal = false"></button>
                  </header>

                  <section class="modal-card-body">
                    <b-field :label="`${i18n.name[iso]}`">
                      <b-input v-model="newBeneficiario.Nombre"></b-input>
                    </b-field>
                    <b-field :label="`${i18n.lastname[iso]}`">
                      <b-input
                        v-model="newBeneficiario.ApellidoPaterno"
                      ></b-input>
                    </b-field>

                    <b-field
                      :label="`${i18n.relationship[iso]}`"
                      v-if="item.type === 'select' && item.isInfo === false"
                      v-for="item in infoBeneficiares"
                      :key="item.key"
                    >
                      <b-select
                        :label="`${i18n.chooseRelationship[iso]}`"
                        v-model="newBeneficiario.Parentesco"
                      >
                        <option
                          v-if="!item.optionsIsCatalogue"
                          v-for="cc in catalogues[item.options]"
                          :value="cc[item.valueOption]"
                          :key="cc[item.valueOption]"
                        >
                          {{ item.showOption(cc) }}
                        </option>
                        <option
                          v-if="item.optionsIsCatalogue"
                          v-for="cc in catalogues[item.options]"
                          :value="cc[item.valueOption]"
                          :key="cc[item.valueOption]"
                        >
                          {{ item.showOption(cc) }}
                        </option>
                      </b-select>
                    </b-field>
                  </section>

                  <footer class="modal-card-foot">
                    <b-button type="is-primary" @click="createBenef()">{{
                      i18n.send[iso]
                    }}</b-button>
                    <button class="button" @click="showModal = false">
                      {{ i18n.cancel[iso] }}
                    </button>
                  </footer>
                </div>
              </b-modal>

              <hr />
              <div
                v-if="
                  contract && contract.Id && club && club.Plantillas.length > 0
                "
              >
                <ContractMember
                  :contract="contract"
                  :templateDocuments="club.Plantillas"
                  @refreshProfile="getProfile"
                />
              </div>
            </b-tab-item>
            <!-- Fin de  la pestaña Contrato -->

            <!-- Pestaña de membresia -->
            <b-tab-item>
              <template #header>
                <b-icon type="is-info" class="fas fa-id-card"></b-icon>
                <b>{{ i18n.membership[iso] }}</b>
              </template>

              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="content">
                    <h3>{{ i18n.membership[iso] }}</h3>
                  </div>

                  <div class="row-content">
                    <div
                      v-for="item in infoContract"
                      :key="item.key"
                      v-if="item.section === 'membresia'"
                    >
                      <div>
                        <div
                          v-if="
                            item.key === 'membershipStatus' &&
                            item.section === 'membresia'
                          "
                        >
                          <b-field
                            label-position="on-border"
                            :label="`${i18n[item.fieldIso][iso]}`"
                          >
                            <b-input
                              :value="
                                item.isParse
                                  ? item.parse(contract[item.key], contract)
                                  : contract[item.key]
                              "
                            >
                            </b-input>
                          </b-field>
                        </div>
                      </div>

                      <div class="column-content">
                        <b-field
                          label-position="on-border"
                          :label="`${i18n[item.fieldIso][iso]}`"
                        >
                          <b-input
                            v-if="item.section === 'membresia'"
                            :value="
                              item.isParse
                                ? item.parse(contract[item.key], contract)
                                : contract[item.key]
                            "
                            disabled
                          >
                          </b-input>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column is-12">
                  <div class="content">
                    <h3>{{ i18n.membershipBenefits[iso] }}</h3>
                  </div>
                  <div>
                    <div class="row-content">
                      <div v-for="item in infoNumberOfWeek" :key="item.key">
                        <div>
                          <b-field
                            label-position="on-border"
                            :label="`${i18n[item.fieldIso][iso]}`"
                            v-if="item.key === 'LastMinuteWeeks'"
                          >
                            <b-input
                              type="text"
                              icon-right="times"
                              icon-right-clickable
                              :value="
                                item.isParse
                                  ? item.key === 'LastMinuteWeeks' &&
                                    item.parse(
                                      numberOfWeek[item.key],
                                      numberOfWeek
                                    ) === 99
                                    ? i18n.unlimited[iso]
                                    : item.parse(
                                        numberOfWeek[item.key],
                                        numberOfWeek
                                      )
                                  : item.key === 'LastMinuteWeeks' &&
                                    numberOfWeek[item.key] === 99
                                  ? i18n.unlimited[iso]
                                  : numberOfWeek[item.key]
                              "
                              disabled
                            />
                          </b-field>

                          <b-field
                            label-position="on-border"
                            :label="`${i18n[item.fieldIso][iso]}`"
                            v-else
                          >
                            <b-input
                              type="text"
                              icon-right="times"
                              icon-right-clickable
                              v-model="numberOfWeek[item.key]"
                              disabled
                            />
                          </b-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column is-12">
                  <div class="content">
                    <h3>{{ i18n.allowAccess[iso] }}</h3>
                  </div>
                </div>
                <div class="column is-2" v-if="permissions === 'admin'">
                  <b-field
                    label-position="on-border"
                    :label="i18n.webTemporary[iso]"
                  >
                    <b-select expanded v-model="contract.AccesoTemporal" @input="editAccesoTemporal">
                      <!-- <option :value="null"></option> -->
                      <option :value="null" v-if="contract.AccesoTemporal !== true && contract.AccesoTemporal !== false">
                        {{ i18n.notAllow[iso] }}
                      </option>
                      <option :value="true">
                        {{ i18n.allow[iso] }}
                      </option>
                      <option :value="false">
                        {{ i18n.notAllow[iso] }}
                      </option>
                      
                    </b-select>
                  </b-field>
                </div>
              </div>
            </b-tab-item>
            <!-- Fin pestaña de membresia -->

            <b-tab-item>
              <template #header>
                <b-icon icon="sync-alt" pack="fas" type="is-info"></b-icon>
                <b>{{ i18n.renovations[iso] }}</b>
              </template>

              <div>
                <p class="subtitle has-text-weight-bold">
                  {{ i18n.subscription[iso] }}
                </p>
                <hr />
                <div
                  class="columns is-vcentered is-justify-content-space-between"
                  style="padding-inline-start: 40px; padding-inline-end: 40px"
                >
                  <div class="is-flex is-align-items-center">
                    <div class="custom-icon">
                      <i class="fas fa-chart-pie fa-2x"></i>
                    </div>
                    <div class="ml-2">
                      <div class="is-flex is-align-items-center">
                        <p class="mr-2 has-text-weight-bold">
                          {{ i18n.annuaPlan[iso] }}
                        </p>
                        <b-tag :type="isExpired ? 'is-danger' : 'is-success'">
                          {{ isExpired ? i18n.expired[iso] : i18n.active[iso] }}
                        </b-tag>
                      </div>
                      <div
                        v-for="item in infoContract"
                        v-if="item.key === 'FechaRenovacion'"
                      >
                        <p
                          class="has-text-weight-medium"
                          v-if="item.type === 'date'"
                        >
                          {{ formattedAnnualPlan(item) }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="column is-2">
                    <b-dropdown aria-role="list" expanded>
                      <template #trigger="{ active }">
                        <b-button
                          :label="i18n.actions[iso]"
                          type="is-info"
                          :icon-right="active ? 'menu-up' : 'menu-down'"
                        />
                      </template>

                      <b-tooltip
                        :label="payLink ? i18n.paymentLink__msg1[iso] : ''"
                        :always="payLink && true"
                        :active="payLink && true"
                        type="is-warning"
                        position="is-left"
                      >
                        <b-dropdown-item
                          aria-role="listitem"
                          @click="openModal('renovar')"
                          :disabled="payLink && true"
                          >{{ i18n.paymentLink[iso] }}</b-dropdown-item
                        >
                      </b-tooltip>
                      <b-dropdown-item
                        aria-role="listitem"
                        @click="openModal('addPayment')"
                        >{{ i18n.addPayment[iso] }}</b-dropdown-item
                      >
                      <b-dropdown-item
                        :disabled="paymentsDeferred.length > 0"
                        aria-role="listitem"
                        @click="openModal('addPaymentDeferred')"
                        >{{ i18n.addPaymentDeferred[iso] }}</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>

                  <!-- <div
                    class="column is-3"
                    v-if="$checkPermission('renew-contract')"
                  >
                    <b-button
                      icon-left="sync"
                      icon-pack="fas"
                      type="is-info"
                      expanded
                      @click="openModal('renovar')"
                    >
                      {{ i18n.renew[iso] }}
                    </b-button>
                  </div> -->
                </div>
                <hr />
              </div>

              <div style="margin-top: 45px" v-if="paymentsDeferred && paymentsDeferred.length > 0">
                <p class="subtitle has-text-weight-bold">
                  {{ i18n.paymentsDeferred[iso] }}
                </p>
                <b-table
                  :height="200"
                  :sticky-header="true"
                  :data="paymentsDeferred"
                  :striped="false"
                  :narrowed="isNarrowed"
                  :hoverable="false"
                  :focusable="isFocusable"
                  detailed
                >
                  <template #detail="props">
                    <b-table
                      :data="props.row.listPayments"
                      :bordered="true"
                      :striped="false"
                      :narrowed="isNarrowed"
                      :hoverable="true"
                      :focusable="isFocusable"
                    >
                      <b-table-column
                        field="stripeId"
                        :label="i18n.payConfirm[iso]"
                        width="150"
                        centered
                        v-slot="propss"
                      >
                        {{ propss.row.stripeId || "" }}
                      </b-table-column>

                      <b-table-column
                        field="type"
                        :label="i18n.paymentMethod[iso]"
                        width="150"
                        centered
                        v-slot="propss"
                      >
                        {{ propss.row.type || "" }}
                      </b-table-column>

                      <b-table-column
                        field="createdAtMs"
                        :label="i18n.payDay[iso]"
                        width="150"
                        centered
                        v-slot="propss"
                      >
                        {{
                          propss.row.createdAtMs
                            ? parseDate(propss.row.createdAtMs)
                            : ""
                        }}
                      </b-table-column>
                      <b-table-column
                        field="amount"
                        :label="i18n.amountPaid[iso]"
                        width="100"
                        centered
                        v-slot="propss"
                      >
                        $ {{ propss.row.amount || "" }} USD
                      </b-table-column>
                    </b-table>
                  </template>

                  <!-- <b-table-column
                    field="Referencia"
                    :label="i18n.payConfirm[iso]"
                    width="150"
                    centered
                    v-slot="props"
                  >
                    {{ props.row.main.stripeId || "" }}
                  </b-table-column>

                  <b-table-column
                    field="FechaPago"
                    :label="i18n.payDay[iso]"
                    width="150"
                    centered
                    v-slot="props"
                  >
                    {{
                      props.row.main.createdAtMs
                        ? parseDate(props.row.main.createdAtMs)
                        : ""
                    }}
                  </b-table-column> -->

                  <b-table-column
                    field="Anualidades"
                    :label="i18n.years[iso]"
                    width="50"
                    centered
                    v-slot="props"
                  >
                    {{ props.row.main.moreInfo.Years || "" }}
                  </b-table-column>

                  <b-table-column
                    field="Period"
                    :label="i18n.startEndPeriod[iso]"
                    width="300"
                    centered
                    v-slot="props"
                  >
                    <div>
                      {{
                        parseDate(
                          props.row.main.moreInfo.FechaInicioPeriodo || ""
                        )
                      }}{{
                        props.row.main.moreInfo.FechaFinPeriodo
                          ? " - " +
                            parseDate(props.row.main.moreInfo.FechaFinPeriodo)
                          : ""
                      }}
                    </div>
                  </b-table-column>

                  <!-- <b-table-column
                    field="Cargo"
                    :label="i18n.amountPaid[iso]"
                    width="100"
                    centered
                    v-slot="props"
                  >
                    $ {{ props.row.main.amount || "" }} USD
                  </b-table-column> -->

                  <b-table-column
                    field="Cargo"
                    :label="i18n.missingAmount[iso]"
                    width="100"
                    centered
                    v-slot="props"
                  >
                    $ {{ props.row.main.missingAmount || "0" }} USD
                  </b-table-column>

                  <b-table-column
                    field="Cargo"
                    :label="i18n.totalToPayment[iso]"
                    width="100"
                    centered
                    v-slot="props"
                  >
                    $ {{ props.row.main.MontoTotalPagoDiferido || "0" }} USD
                  </b-table-column>

                  <b-table-column
                    field="Cargo"
                    :label="i18n.createdBy[iso]"
                    width="100"
                    centered
                    v-slot="props"
                  >
                    {{ props.row.main.CreadoPor || "" }}
                  </b-table-column>

                  <b-table-column
                    label="Actions"
                    v-slot="{ row }"
                    centered
                    width="150"
                  >
                    <b-tooltip type="is-info" position="is-left">
                      <b-button
                        icon-left="plus"
                        icon-pack="fas"
                        size="is-small"
                        type="is-info"
                        @click="openModal('addPaymentDeferred__step1')"
                      ></b-button>
                      <template v-slot:content>
                        <b>{{ i18n.addPayment[iso] }}</b></template
                      >
                    </b-tooltip>
                    <!-- <b-tooltip type="is-success" position="is-left">
                      <b-button
                        icon-left="check"
                        icon-pack="fas"
                        size="is-small"
                        type="is-success"
                        @click="openModal('addPaymentDeferred__step2')"
                      ></b-button>
                      <template v-slot:content>
                        <b>{{ i18n.completePayment[iso] }}</b></template
                      >
                    </b-tooltip> -->
                  </b-table-column>
                </b-table>
              </div>

              <div style="margin-top: 45px">
                <p class="subtitle has-text-weight-bold">
                  {{ i18n.payments[iso] }}
                </p>
                <b-table
                  :height="400"
                  :sticky-header="true"
                  :data="sortedRenovations"
                  :striped="false"
                  :narrowed="isNarrowed"
                  :hoverable="false"
                  :focusable="isFocusable"
                  :row-class="(row, index) => row.isPayLink && 'is-info'"
                  detailed
                >
                <template #detail="props">
                    <b-table
                      :data="props.row.payments"
                      :bordered="true"
                      :striped="false"
                      :narrowed="isNarrowed"
                      :hoverable="true"
                      :focusable="isFocusable"
                    >
                      <b-table-column
                        field="stripeId"
                        :label="i18n.payConfirm[iso]"
                        width="150"
                        centered
                        v-slot="propss"
                      >
                        {{ propss.row.stripeId || "" }}
                      </b-table-column>

                      <b-table-column
                        field="type"
                        :label="i18n.paymentMethod[iso]"
                        width="150"
                        centered
                        v-slot="propss"
                      >
                        {{ propss.row.type || "" }}
                      </b-table-column>

                      <b-table-column
                        field="createdAtMs"
                        :label="i18n.payDay[iso]"
                        width="150"
                        centered
                        v-slot="propss"
                      >
                        {{
                          propss.row.createdAtMs
                            ? parseDate(propss.row.createdAtMs)
                            : ""
                        }}
                      </b-table-column>
                      <b-table-column
                        field="amount"
                        :label="i18n.amountPaid[iso]"
                        width="100"
                        centered
                        v-slot="propss"
                      >
                        $ {{ propss.row.amount || "" }} USD
                      </b-table-column>
                    </b-table>
                  </template>

                  <b-table-column
                    field="SolicitudId"
                    label="Folio"
                    width="50"
                    centered
                    v-slot="props"
                  >
                    {{ props.row.SolicitudId }}
                  </b-table-column>

                  <b-table-column
                    field="FechaPago"
                    :label="i18n.payDay[iso]"
                    width="150"
                    centered
                    v-slot="props"
                  >
                    {{
                      props.row.FechaPago ? parseDate(props.row.FechaPago) : ""
                    }}
                  </b-table-column>

                  <b-table-column
                    field="Anualidades"
                    :label="i18n.periods[iso]"
                    width="50"
                    centered
                    v-slot="props"
                  >
                    {{ props.row.Anualidades }}
                  </b-table-column>

                  <b-table-column
                    field="Period"
                    :label="i18n.startEndPeriod[iso]"
                    width="400"
                    centered
                    v-slot="props"
                  >
                    <div>
                      {{ parseDate(props.row.FechaInicioPeriodo || "")
                      }}{{
                        props.row.FechaFinPeriodo
                          ? " - " + parseDate(props.row.FechaFinPeriodo)
                          : ""
                      }}
                    </div>
                  </b-table-column>
                  <!-- <b-table-column
                  field="FechaInicioPeriodo"
                  :label="i18n.dateStartPeriod[iso]"
                  width="150"
                  centered
                  v-slot="props"
                >
                  {{
                    props.row.FechaInicioPeriodo
                      ? parseDate(props.row.FechaInicioPeriodo)
                      : ""
                  }}
                </b-table-column>

                <b-table-column
                  field="FechaFinPeriodo"
                  :label="i18n.dateEndPeriod[iso]"
                  width="150"
                  centered
                  v-slot="props"
                >
                  {{
                    props.row.FechaFinPeriodo
                      ? parseDate(props.row.FechaFinPeriodo)
                      : ""
                  }}
                </b-table-column> -->

                  <b-table-column
                    field="ConfirmacionPago"
                    :label="i18n.payConfirm[iso]"
                    width="150"
                    centered
                    v-slot="props"
                  >
                    <div class="short-text">
                      {{ props.row.ConfirmacionPago || "" }}
                    </div>
                  </b-table-column>

                  <b-table-column
                    field="Cargo"
                    :label="i18n.amount[iso]"
                    width="150"
                    centered
                    v-slot="props"
                  >
                    $ {{ props.row.Cargo }} USD
                  </b-table-column>

                  <b-table-column
                    field="Codigo"
                    :label="i18n.user[iso]"
                    width="150"
                    centered
                    v-slot="props"
                  >
                    {{
                      props.row.MemberId === "na"
                        ? "Online"
                        : props.row.MemberId
                    }}
                  </b-table-column>
                  <!-- <b-table-column
                  field="Comentarios"
                  :label="i18n.comments[iso]"
                  width="350"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Comentarios || "" }}
                </b-table-column> -->
                  <b-table-column
                    label="Actions"
                    v-slot="{ row }"
                    centered
                    width="150"
                  >
                    <div v-if="!row.isPayLink">
                      <!-- <b-tooltip type="is-success is-light">
                        <b-button
                          icon-left="eye"
                          icon-pack="fas"
                          size="is-small"
                          type="is-success is-light"
                          @click="goPath(row)"
                          style="margin-right: 10px"
                        ></b-button>
                        <template v-slot:content>
                          <b>{{ i18n.paymantDetails[iso] }}</b></template
                        >
                      </b-tooltip> -->
                    </div>
                    <div v-if="row.isPayLink">
                      <b-tooltip type="is-success" position="is-left">
                        <b-button
                          icon-left="copy"
                          icon-pack="fas"
                          size="is-small"
                          type="is-success"
                          @click="copyPaymentLink(row.PayLinkId)"
                          style="margin-right: 10px"
                        ></b-button>
                        <template v-slot:content>
                          <b>{{ i18n.copyPaymentLink[iso] }}</b></template
                        >
                      </b-tooltip>
                      <b-tooltip type="is-info" position="is-left">
                        <b-button
                          icon-left="paper-plane"
                          icon-pack="fas"
                          size="is-small"
                          type="is-info"
                          @click="reSendPaymentLink(row.PayLinkId)"
                          style="margin-right: 10px"
                          :loading="loadingResendPaymentLink"
                        ></b-button>
                        <template v-slot:content>
                          <b>{{ i18n.sendLinkPayment[iso] }}</b></template
                        >
                      </b-tooltip>
                      <b-tooltip type="is-danger" position="is-left">
                        <b-button
                          icon-left="times"
                          icon-pack="fas"
                          size="is-small"
                          type="is-danger"
                          @click="cancelPaymentLink(row.PayLinkId)"
                          :loading="loadingCancelPaymentLink"
                        ></b-button>
                        <template v-slot:content>
                          <b>{{ i18n.cancelLinkPayment[iso] }}</b></template
                        >
                      </b-tooltip>
                    </div>
                  </b-table-column>
                </b-table>
              </div>

              <template>
                <b-modal
                  :active.sync="showModal"
                  scroll="keep"
                  has-modal-card
                  v-if="modalType === 'renovar'"
                >
                  <div
                    v-if="modalStep === 'renew'"
                    class="card"
                    style="width: 600px"
                  >
                    <header class="modal-card-head">
                      <p class="modal-card-title">{{ i18n.renew[iso] }}</p>
                      <button
                        class="delete"
                        @click="showModal = false"
                      ></button>
                    </header>
                    <section class="modal-card-body">
                      <b-field
                        label-position="on-border"
                        :label="i18n.periods[iso]"
                      >
                        <b-select
                          v-model="yearsRenew"
                          placeholder="Select Periods"
                          expanded
                        >
                          <option
                            v-for="option in periodOptions"
                            :key="option.value"
                            :value="option.value"
                            class="p-3"
                          >
                            {{ option.label }}
                          </option>
                        </b-select></b-field
                      >

                      <!-- <b-field
                        label-position="on-border"
                        style="margin-bottom: 25px"
                      >
                        <p
                          v-if="yearsRenew"
                          class="has-text-weight-bold mt-3 has-background-success mb-2 p-2 has-text-white"
                          style="
                            width: 60%;
                            border-radius: 5px;
                            margin-bottom: 25px;
                          "
                        >
                          De
                          {{
                            parseDate(
                              contract.FechaRenovacion || contract.FechaRegistro
                            )
                          }}
                          A
                          {{
                            parseDate(
                              calculateNewExpirationDate(
                                contract.FechaRenovacion || contract.FechaRegistro,
                                yearsRenew
                              )
                            )
                          }}
                        </p>
                      </b-field> -->

                      <div class="columns">
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="i18n.promotionCode[iso]"
                          >
                            <b-select v-model="cuponRenew" expanded>
                              <option
                                v-for="option in cupons"
                                :key="option.code"
                                :value="option.code"
                              >
                                <span
                                  v-if="
                                    option.discount === 0 &&
                                    option.code !== 'RESET'
                                  "
                                ></span>
                                <span v-else-if="option.code === 'RESET'">
                                  {{ option.code }}
                                </span>
                                <span v-else
                                  >{{ option.code }} ({{
                                    option.discount
                                  }}%)</span
                                >
                              </option>
                            </b-select></b-field
                          >
                        </div>
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="i18n.sendMailToRenewal[iso]"
                          >
                            <b-select v-model="PrincipalPagador" expanded>
                              <option :value="'T'">
                                {{ i18n.owner[iso] }}
                              </option>
                              <option :value="'C'">
                                {{ i18n.coOwner[iso] }}
                              </option>
                              <option :value="'B'">
                                {{ i18n.both[iso] }}
                              </option>
                            </b-select></b-field
                          >
                        </div>
                      </div>

                      <div class="columns">
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="i18n.amount[iso]"
                          >
                            <b-input
                              type="text"
                              :value="totalAmountPaymentLink"
                              disabled
                            ></b-input>
                          </b-field>
                        </div>
                      </div>

                      <div class="columns" v-if="permissions === 'admin'">
                        <div
                          class="column is-6"
                          style="padding-left: 60px; padding-top: 15px"
                        >
                          <b-tooltip
                            :label="i18n.forgiveDebt[iso]"
                            position="is-right"
                            type="is-info"
                          >
                            <b-field>
                              <b-switch
                                v-model="codeReset"
                                type="is-info"
                                size="is-medium"
                              >
                                <b>RESET</b>
                              </b-switch>
                            </b-field>
                          </b-tooltip>
                          <span v-if="codeReset">
                            <br />
                            <b-tag type="is-info is-light"
                              ><p>
                                <b>
                                  {{ i18n.condoned[iso] }}:
                                  {{
                                    periodOptions.find(
                                      (p) => p.value === yearsRenew
                                    ).YearsCondoned
                                  }}
                                  {{ i18n.years[iso] }}</b
                                >
                              </p></b-tag
                            >
                            <br />
                            <!-- <b-tag type="is-primary is-light"
                              ><p><b>{{
                                  periodOptions.find(
                                    (p) => p.value === yearsRenew
                                  ).showPeriodIfCodeReset
                                }}</b></p
                            ></b-tag> -->
                          </span>
                        </div>
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="i18n.renewalAssign[iso]"
                          >
                            <b-select expanded v-model="agentEmail">
                              <option
                                :value="user.Email"
                                v-for="user in usersStaff"
                              >
                                {{ user.Nombre }} {{ user.Apellido }}
                              </option>
                            </b-select>
                          </b-field>
                        </div>
                      </div>

                      <!-- <b-field
                        label-position="on-border"
                        :label="i18n.promotionCode[iso]"
                      >
                        <b-select v-model="cuponRenew" expanded>
                          <option
                            v-for="option in cupons"
                            :key="option.code"
                            :value="option.code"
                          >
                            {{ option.code }}
                          </option>
                        </b-select></b-field
                      > -->

                      <!-- <b-field grouped style="margin-bottom: 25px">
                        <b-field
                          label-position="on-border"
                          :label="i18n.promotionCode[iso]"
                          expanded
                        >
                          <b-input type="text" v-model="cuponRenew"></b-input>
                        </b-field>
                        <b-field expanded>
                          <b-button class="is-primary" expanded>
                            {{ i18n.apply[iso] }}
                          </b-button>
                        </b-field>
                      </b-field> -->
                    </section>

                    <footer class="modal-card-foot">
                      <button class="button" @click="showModal = false">
                        {{ i18n.cancel[iso] }}
                      </button>
                      <b-button
                        :loading="loadingButtonRenewalPaymentLink"
                        type="is-success"
                        @click="sendRenew(yearsRenew, cuponRenew)"
                        >{{ i18n.create[iso] }}</b-button
                      >
                      <!--  <button
                        class="button is-primary"
                        @click="modalStep = 'options'"
                      >
                        Cambiar
                      </button> -->
                    </footer>
                  </div>

                  <div
                    v-if="modalStep === 'options'"
                    class="card"
                    style="width: 500px"
                  >
                    <header class="modal-card-head">
                      <p class="modal-card-title">{{ i18n.options[iso] }}</p>
                      <button
                        class="delete"
                        @click="showModal = false"
                      ></button>
                    </header>
                    <section class="modal-card-body">
                      <p>{{ i18n.selectAnOption[iso] }}</p>
                      <div class="buttons mt-3">
                        <div class="button-wrapper">
                          <b-button
                            @click="handleButtonClick('copy')"
                            :type="
                              selectedOption === 'copy' ? 'is-success' : ''
                            "
                          >
                            <i class="fas fa-copy"></i>
                          </b-button>
                        </div>

                        <div class="button-wrapper">
                          <b-button
                            @click="handleButtonClick('sms')"
                            :type="selectedOption === 'sms' ? 'is-success' : ''"
                          >
                            <i class="fas fa-comment-alt"></i>
                          </b-button>
                        </div>
                      </div>

                      <div
                        v-if="selectedOption === 'copy'"
                        class="has-text-centered mt-4"
                      >
                        <hr />
                        <p class="has-text-grey renewUrl">
                          <b-icon icon="link" class="mr-1"></b-icon>
                          <span class="truncated-url">{{ renewalUrl }}</span>
                        </p>
                      </div>

                      <div v-if="selectedOption === 'sms'" class="mt-4">
                        <div>
                          <hr />
                          <div>
                            <p class="subtitle">Sms</p>
                            <b-field grouped style="margin-bottom: 18px">
                              <b-field
                                expanded
                                label-position="on-border"
                                label="Owner"
                              >
                                <b-input
                                  :value="
                                    member.LadaTelefono && member.Telefono
                                      ? `+${member.LadaTelefono} ${member.Telefono}`
                                      : ''
                                  "
                                  type="text"
                                  disabled
                                  expanded
                                ></b-input>
                              </b-field>

                              <b-field
                                expanded
                                label-position="on-border"
                                label="Co-Owner"
                              >
                                <b-input
                                  :value="
                                    coOwner.LadaTelefono && coOwner.Telefono
                                      ? `+${coOwner.LadaTelefono} ${coOwner.Telefono}`
                                      : ''
                                  "
                                  type="text"
                                  disabled
                                  expanded
                                ></b-input>
                              </b-field>
                            </b-field>

                            <b-field grouped>
                              <b-field
                                label-position="on-border"
                                label="Phone Number"
                                expanded
                              >
                                <b-input
                                  placeholder="Ingresar número"
                                  type="phone"
                                  v-model="newPhoneNumber"
                                  expanded
                                ></b-input>
                              </b-field>
                              <b-field>
                                <b-button type="is-primary" @click="addNumber"
                                  >Agregar</b-button
                                >
                              </b-field>
                            </b-field>
                            <div
                              class="is-half"
                              v-for="(phone, index) in phones"
                              :key="index"
                              style="margin-bottom: 9px"
                            >
                              <b-field grouped>
                                <b-field expanded>
                                  <b-input
                                    :value="phone"
                                    disabled
                                    type="text"
                                    expanded
                                  ></b-input>
                                </b-field>
                                <b-field>
                                  <b-button
                                    type="is-danger"
                                    @click="removeNumber(index)"
                                    expanded
                                    ><b-icon
                                      icon="trash"
                                      class="fas fa-trash"
                                    ></b-icon
                                  ></b-button>
                                </b-field>
                              </b-field>
                            </div>
                          </div>
                          <hr />
                          <div>
                            <p class="subtitle">{{ i18n.email[iso] }}</p>
                            <b-field grouped style="margin-bottom: 18px">
                              <b-field
                                expanded
                                label-position="on-border"
                                label="Owner"
                              >
                                <b-input
                                  :value="member.Email ? member.Email : ''"
                                  type="text"
                                  disabled
                                  expanded
                                ></b-input>
                              </b-field>

                              <b-field
                                expanded
                                label-position="on-border"
                                label="Co-Owner"
                              >
                                <b-input
                                  :value="coOwner.Email ? coOwner.Email : ''"
                                  type="text"
                                  disabled
                                  expanded
                                ></b-input>
                              </b-field>
                            </b-field>

                            <b-field grouped style="margin-bottom: 15px">
                              <b-field
                                label-position="on-border"
                                :label="i18n.email[iso]"
                                expanded
                              >
                                <b-input
                                  placeholder="Ingresar correo"
                                  type="email"
                                  v-model="newEmail"
                                  expanded
                                ></b-input>
                              </b-field>
                              <b-field>
                                <b-button type="is-primary" @click="addEmails"
                                  >Agregar</b-button
                                >
                              </b-field>
                            </b-field>

                            <div
                              class="is-half"
                              v-for="(email, index) in emails"
                              :key="index"
                              style="margin-bottom: 9px"
                            >
                              <b-field grouped>
                                <b-field expanded>
                                  <b-input
                                    :value="email"
                                    disabled
                                    type="text"
                                    expanded
                                  ></b-input>
                                </b-field>
                                <b-field>
                                  <b-button
                                    type="is-danger"
                                    @click="removeEmail(index)"
                                    expanded
                                    ><b-icon
                                      icon="trash"
                                      class="fas fa-trash"
                                    ></b-icon
                                  ></b-button>
                                </b-field>
                              </b-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <footer class="modal-card-foot">
                      <!-- <button
                        class="button is-primary"
                        @click="sendRenew(yearsRenew, cuponRenew)"
                      >
                        {{ i18n.accept[iso] }}
                      </button> -->
                      <button class="button" @click="modalStep = 'renew'">
                        {{ i18n.goBack[iso] }}
                      </button>
                      <button
                        class="button is-primary"
                        @click="showModal = false"
                      >
                        {{ i18n.accept[iso] }}
                      </button>
                    </footer>
                  </div>
                </b-modal>

                <b-modal
                  :active.sync="showModal"
                  scroll="keep"
                  has-modal-card
                  v-if="modalType === 'addPayment'"
                >
                  <div class="card" style="width: 500px">
                    <header class="modal-card-head">
                      <p class="modal-card-title">
                        {{ i18n.addPayExternal[iso] }}
                      </p>
                      <button
                        class="delete"
                        @click="showModal = false"
                      ></button>
                    </header>
                    <section class="modal-card-body">
                      <div class="columns is-multiline">
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="i18n.paymentMethod[iso]"
                            required
                          >
                            <b-select v-model="formPayment.TipoPago" expanded>
                              <option
                                v-for="typeE in typePaymentsExternal"
                                :value="typeE.id"
                              >
                                {{
                                  iso == "en" ? typeE.NombreEN : typeE.NombreES
                                }}
                              </option>
                            </b-select>
                          </b-field>
                        </div>
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            label="Referencia"
                          >
                            <b-input
                              type="text"
                              v-model="formPayment.Referencia"
                            ></b-input>
                          </b-field>
                        </div>
                        <div class="column is-12">
                          <b-field
                            label-position="on-border"
                            :label="i18n.periods[iso]"
                          >
                            <b-select
                              v-model="formPayment.Years"
                              expanded
                              @input="onChangeYears"
                            >
                              <option
                                v-for="option in periodOptions"
                                :key="option.value"
                                :value="option.value"
                              >
                                {{ option.label }}
                              </option>
                            </b-select>
                          </b-field>
                        </div>
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="i18n.payDay[iso]"
                            expanded
                          >
                            <b-input
                              type="date"
                              v-model="formPayment.FechaPago"
                              :max="maxDate"
                              required
                            ></b-input>
                          </b-field>
                        </div>
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="`${i18n.amount[iso]} (USD)`"
                            expanded
                          >
                            <b-input
                              type="number"
                              v-model="formPayment.Monto"
                            ></b-input>
                          </b-field>
                        </div>
                        <div
                          class="column is-6"
                          v-if="permissions === 'admin'"
                          style="padding-left: 10px; padding-top: 15px"
                        >
                          <b-tooltip
                            :label="i18n.forgiveDebt[iso]"
                            position="is-right"
                            type="is-info"
                          >
                            <b-field>
                              <b-switch
                                v-model="codeReset"
                                type="is-info"
                                size="is-medium"
                              >
                                <b>RESET</b>
                              </b-switch>
                            </b-field>
                          </b-tooltip>
                          <span v-if="codeReset">
                            <br />
                            <b-tag type="is-info is-light"
                              ><p>
                                <b>
                                  {{ i18n.condoned[iso] }}:
                                  {{
                                    periodOptions.find(
                                      (p) => p.value === yearsRenew
                                    ).YearsCondoned
                                  }}
                                  {{ i18n.years[iso] }}</b
                                >
                              </p></b-tag
                            >
                            <br />
                            <!-- <b-tag type="is-primary is-light"
                              ><p><b>{{
                                  periodOptions.find(
                                    (p) => p.value === yearsRenew
                                  ).showPeriodIfCodeReset
                                }}</b></p
                            ></b-tag> -->
                          </span>
                        </div>
                      </div>

                      <!-- <b-field grouped style="margin-bottom: 25px">
                        <b-field
                          label-position="on-border"
                          :label="i18n.promotionCode[iso]"
                          expanded
                        >
                          <b-input
                            type="text"
                            v-model="formPayment.codeDiscount"
                          ></b-input>
                        </b-field>
                        <b-field expanded>
                          <b-button class="is-primary" expanded>
                            {{ i18n.apply[iso] }}
                          </b-button>
                        </b-field>
                      </b-field> -->

                      <!-- <b-field
                        label-position="on-border"
                        :label="i18n.payDay[iso]"
                        expanded
                        v-if="formPayment.type === paymentMethod[4]._id"
                      >
                        <b-input
                          type="date"
                          v-model="formPayment.datePay"
                          :max="maxDate"
                          required
                        ></b-input>
                      </b-field>

                      <b-field v-else grouped style="margin-bottom: 25px">
                        <b-field
                          label-position="on-border"
                          :label="i18n.payDay[iso]"
                          expanded
                        >
                          <b-input
                            type="date"
                            v-model="formPayment.datePay"
                            :max="maxDate"
                            required
                          ></b-input>
                        </b-field>

                        <b-field
                          label-position="on-border"
                          :label="i18n.amount[iso]"
                          expanded
                        >
                          <b-input
                            type="text"
                            :value="totalAmount"
                            disabled
                          ></b-input>
                        </b-field>
                      </b-field> -->
                    </section>

                    <footer class="modal-card-foot">
                      <button class="button" @click="showModal = false">
                        {{ i18n.cancel[iso] }}
                      </button>
                      <b-button
                        class="button is-success"
                        @click="sendPayment"
                        :loading="loadingButtonSendPaymentExternal"
                        >{{ i18n.accept[iso] }}</b-button
                      >
                    </footer>
                  </div>
                </b-modal>

                <b-modal
                  :active.sync="showModal"
                  scroll="keep"
                  has-modal-card
                  v-if="modalType === 'addPaymentDeferred'"
                >
                  <div class="card" style="width: 600px">
                    <header class="modal-card-head">
                      <p class="modal-card-title">
                        {{ i18n.addPaymentDeferred[iso] }}
                      </p>
                      <button
                        class="delete"
                        @click="showModal = false"
                      ></button>
                    </header>
                    <section class="modal-card-body">
                      <div class="columns is-multiline">
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="i18n.paymentMethod[iso]"
                            required
                          >
                            <b-select v-model="formPayment.TipoPago" expanded>
                              <option
                                v-for="typeE in typePaymentsExternal"
                                :value="typeE.id"
                              >
                                {{
                                  iso == "en" ? typeE.NombreEN : typeE.NombreES
                                }}
                              </option>
                            </b-select>
                          </b-field>
                        </div>
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            label="Referencia"
                          >
                            <b-input
                              type="text"
                              v-model="formPayment.Referencia"
                            ></b-input>
                          </b-field>
                        </div>
                        <div class="column is-12">
                          <b-field
                            label-position="on-border"
                            :label="i18n.periods[iso]"
                          >
                            <b-select
                              v-model="formPayment.Years"
                              expanded
                              @input="onChangeYears"
                            >
                              <option
                                v-for="option in periodOptions"
                                :key="option.value"
                                :value="option.value"
                              >
                                {{ option.label }}
                              </option>
                            </b-select>
                          </b-field>
                        </div>
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="i18n.payDay[iso]"
                            expanded
                          >
                            <b-input
                              type="date"
                              v-model="formPayment.FechaPago"
                              :max="maxDate"
                              required
                            ></b-input>
                          </b-field>
                        </div>
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="`${i18n.initialPayment[iso]} (USD)`"
                            expanded
                          >
                            <!-- <b-input
                              type="number"
                              v-model="formPayment.AbonoInicial"
                            ></b-input> -->
                            <b-numberinput v-model="formPayment.AbonoInicial" 
                            :controls="false" step="0.01"></b-numberinput>
                          </b-field>
                        </div>
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="`${i18n.totalPayment[iso]} (USD)`"
                            expanded
                          >
                            <b-input
                              disabled
                              type="number"
                              v-model="formPayment.Monto"
                            ></b-input>
                          </b-field>
                        </div>
                        <div
                          class="column is-6"
                          v-if="permissions === 'admin'"
                          style="padding-left: 10px; padding-top: 15px"
                        >
                          <b-tooltip
                            :label="i18n.forgiveDebt[iso]"
                            position="is-right"
                            type="is-info"
                          >
                            <b-field>
                              <b-switch
                                v-model="codeReset"
                                type="is-info"
                                size="is-medium"
                              >
                                <b>RESET</b>
                              </b-switch>
                            </b-field>
                          </b-tooltip>
                          <span v-if="codeReset">
                            <br />
                            <b-tag type="is-info is-light"
                              ><p>
                                <b>
                                  {{ i18n.condoned[iso] }}:
                                  {{
                                    periodOptions.find(
                                      (p) => p.value === yearsRenew
                                    ).YearsCondoned
                                  }}
                                  {{ i18n.years[iso] }}</b
                                >
                              </p></b-tag
                            >
                            <br />
                            <!-- <b-tag type="is-primary is-light"
                              ><p><b>{{
                                  periodOptions.find(
                                    (p) => p.value === yearsRenew
                                  ).showPeriodIfCodeReset
                                }}</b></p
                            ></b-tag> -->
                          </span>
                        </div>
                      </div>

                      <!-- <b-field grouped style="margin-bottom: 25px">
                        <b-field
                          label-position="on-border"
                          :label="i18n.promotionCode[iso]"
                          expanded
                        >
                          <b-input
                            type="text"
                            v-model="formPayment.codeDiscount"
                          ></b-input>
                        </b-field>
                        <b-field expanded>
                          <b-button class="is-primary" expanded>
                            {{ i18n.apply[iso] }}
                          </b-button>
                        </b-field>
                      </b-field> -->

                      <!-- <b-field
                        label-position="on-border"
                        :label="i18n.payDay[iso]"
                        expanded
                        v-if="formPayment.type === paymentMethod[4]._id"
                      >
                        <b-input
                          type="date"
                          v-model="formPayment.datePay"
                          :max="maxDate"
                          required
                        ></b-input>
                      </b-field>

                      <b-field v-else grouped style="margin-bottom: 25px">
                        <b-field
                          label-position="on-border"
                          :label="i18n.payDay[iso]"
                          expanded
                        >
                          <b-input
                            type="date"
                            v-model="formPayment.datePay"
                            :max="maxDate"
                            required
                          ></b-input>
                        </b-field>

                        <b-field
                          label-position="on-border"
                          :label="i18n.amount[iso]"
                          expanded
                        >
                          <b-input
                            type="text"
                            :value="totalAmount"
                            disabled
                          ></b-input>
                        </b-field>
                      </b-field> -->
                    </section>

                    <footer class="modal-card-foot">
                      <button class="button" @click="showModal = false">
                        {{ i18n.cancel[iso] }}
                      </button>
                      <b-button
                        class="button is-success"
                        @click="sendPaymentDeferred"
                        :loading="loadingButtonSendPaymentDeferred"
                        >{{ i18n.accept[iso] }}</b-button
                      >
                    </footer>
                  </div>
                </b-modal>

                <b-modal
                  :active.sync="showModal"
                  scroll="keep"
                  has-modal-card
                  v-if="modalType === 'addPaymentDeferred__step1'"
                >
                  <div class="card" style="width: 600px">
                    <header class="modal-card-head">
                      <p class="modal-card-title">
                        {{ i18n.addPayment[iso] }}
                      </p>
                      <button
                        class="delete"
                        @click="showModal = false"
                      ></button>
                    </header>
                    <section class="modal-card-body">
                      <div class="columns is-multiline">
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="i18n.paymentMethod[iso]"
                            required
                          >
                            <b-select v-model="formPayment.TipoPago" expanded>
                              <option
                                v-for="typeE in typePaymentsExternal"
                                :value="typeE.id"
                              >
                                {{
                                  iso == "en" ? typeE.NombreEN : typeE.NombreES
                                }}
                              </option>
                            </b-select>
                          </b-field>
                        </div>
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            label="Referencia"
                          >
                            <b-input
                              type="text"
                              v-model="formPayment.Referencia"
                            ></b-input>
                          </b-field>
                        </div>
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="i18n.payDay[iso]"
                            expanded
                          >
                            <b-input
                              type="date"
                              v-model="formPayment.FechaPago"
                              :max="maxDate"
                              required
                            ></b-input>
                          </b-field>
                        </div>
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="`${i18n.amountToPay[iso]} (USD)`"
                            expanded
                            :type="paymentsDeferred[0].main.missingAmount - formPayment.AbonoInicial < 0 ?  'is-danger' : ''"
                            :message="paymentsDeferred[0].main.missingAmount - formPayment.AbonoInicial < 0 ?  i18n.amountToPay__msg1[iso] : ''"
                          >
                            <!-- <b-input
                              type="number"
                              v-model="formPayment.AbonoInicial"
                            ></b-input> -->
                            <b-numberinput v-model="formPayment.AbonoInicial" 
                            :controls="false" step="0.01"></b-numberinput>
                          </b-field>
                        </div>
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="`${i18n.totalPayment[iso]} (USD)`"
                            expanded
                          >
                            <b-input
                              disabled
                              type="number"
                              :value="paymentsDeferred[0].main.MontoTotalPagoDiferido"
                            ></b-input>
                          </b-field>
                        </div>
                        <div class="column is-6">
                          <b-field
                            label-position="on-border"
                            :label="`${i18n.missingAmount[iso]} (USD)`"
                            expanded
                            :type="paymentsDeferred[0].main.missingAmount - formPayment.AbonoInicial === 0 ?  'is-success' : ''"
                            :message="paymentsDeferred[0].main.missingAmount - formPayment.AbonoInicial === 0 ?  i18n.paymentCompleted[iso] : ''"
                          >
                            <b-input
                              disabled
                              type="number"
                              :value="(paymentsDeferred[0].main.missingAmount - formPayment.AbonoInicial).toFixed(2)"
                            ></b-input>
                          </b-field>
                        </div>
                      </div>
                    </section>

                    <footer class="modal-card-foot">
                      <button class="button" @click="showModal = false">
                        {{ i18n.cancel[iso] }}
                      </button>
                      <b-button
                        :disabled="paymentsDeferred[0].main.missingAmount - formPayment.AbonoInicial < 0"
                        class="button is-success"
                        @click="sendPaymentDeferred"
                        :loading="loadingButtonSendPaymentDeferred"
                        >{{ i18n.accept[iso] }}</b-button
                      >
                    </footer>
                  </div>
                </b-modal>
              </template>
            </b-tab-item>

            <b-tab-item>
              <template #header>
                <b-icon icon="comment" pack="fas" type="is-info"></b-icon>
                <b>{{ i18n.comments[iso] }}</b>
              </template>
              <div class="columns is-multiline"></div>
              <comment-member
                ref="commentmember"
                v-if="contract.Id"
                :ContratoId="contract.Id"
              ></comment-member>
            </b-tab-item>

            <!-- Pestaña de acciones -->
            <b-tab-item>
              <template #header>
                <b-icon icon="hand-pointer" pack="fas" type="is-info"></b-icon>
                <b>Acciones</b>
              </template>

              <div v-if="contract && member.Email">
                <ActionsMember
                  :contract="data.member.ContratoId"
                  :member="data.member"
                  :coOwner="coOwner"
                  :ifSyncBitrix="ifSyncBitrix"
                  :email="member.Email || ''"
                  @refresh-profile="getProfile"
                  :isAdmin="permissions === 'admin'"
                />
              </div>
            </b-tab-item>
            <!-- Fin de pestaña de acciones -->
          </b-tabs>
          <hr />
          <!-- <div class="content article-body"></div> -->
        </div>
      </div>
    </section>

    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n, {
  addPayment,
  amount,
  beneficiaries,
  contract,
} from "../../utils/i18n";
import codCountries from "../../utils/codeCountries";
import CommentMember from "./CommentMember";
import ActionsMember from "./ActionsMember.vue";
import ContractMember from "./ContractMember.vue";

const NO_MORE_FEES = "No more fees";
const cupons = [
  {
    discount: 0,
    code: "",
  },
  {
    discount: 5,
    code: "RNCC05",
  },
  {
    discount: 10,
    code: "RNCC10",
  },
  {
    discount: 15,
    code: "RNCC15",
  },
  {
    discount: 20,
    code: "RNCC20",
  },
  {
    discount: 25,
    code: "RNCC25",
  },
];
// {
//   discount: 0,
//   code: "RESET",
// },

const typePaymentsExternal = [
  {
    NombreEN: "Stripe",
    NombreES: "Stripe",
    id: "stripe",
  },
  {
    NombreEN: "Cash",
    NombreES: "Efectivo",
    id: "cash",
  },
  {
    NombreEN: "Wire Transfer",
    NombreES: "Transferencia Bancaria",
    id: "transfer",
  },
  {
    NombreEN: "Tarjeta de Crédito Converge",
    NombreES: "Credit Card Converge",
    id: "cardConverge",
  },
  {
    NombreEN: "CXC Employee",
    NombreES: "CXC Empleado",
    id: "cxcEmployee",
  },
  {
    NombreEN: "Discount",
    NombreES: "Descuento",
    id: "discount",
  },
  // {
  //   NombreEN: "Travel Voucher Club",
  //   NombreES: "Travel Voucher Club",
  //   id: "travelVoucher",
  // },

  {
    NombreEN: "Courtesy",
    NombreES: "Cortesía",
    id: "courtesy",
  },
];

const TEMPLATE_MEMBER = {
  Nombre: null,
  ApellidoPaterno: null,
  // ApellidoMaterno: null,
  Email: null,
  FechaNacimiento: null,
  LadaTelefono: null,
  Telefono: null,
  LadaCelular: null,
  Celular: null,
  Pais: null,
  Estado: null,
  Ciudad: null,
  Direccion: null,
  CodigoPostal: null,
  Nacionalidad: null,
  EstadoCivil: null,
  TipoSocio: null,
  FechaRegistro: null,
  Idioma: null,
};

const TEMPLATE_CONTRACT = {
  Numero: null,
  FechaRenovacion: null,
  FechaCancelado: null,
  LoginID: null,
  Id: null,
  EstadoMembresia: false,
  EstadoContrato: 1,
  DiscountTravelVoucher: null,
  Cantidad: null,
  Monto: null,
  Idioma: "",
  FechaRegistro: null,
  FechaExpiracion: null,
  FechaActivacion: null,
};

const listCatalogues = [
  "CatEstadosPais",
  "CatEstadoCivil",
  "CatIdiomas",
  "CatTipoSocio",
  "CatPaises",
  "CatPeriodicidad",
  "CatStatusContrato",
  "CatTipoVenta",
  "CatParentescos",
];

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      isExpired: false,
      hasMobileCards: true,
      memberId: null,
      coMemberId: null,
      beMemberId: null,
      showModal: false,
      coin: null,
      newStatus: "",
      modalType: "",
      yearsRenew: 1,
      cuponRenew: null,
      modalStep: "renew",
      selectedOption: null,
      renewalUrl: "",
      copyMessage: "",

      //addPayment
      userId: null,
      //i18n
      i18n,
      iso: null,

      //Permissions
      permissions: "",
      showButtons: false,

      //SMS
      newPhoneNumber: null,
      newEmail: null,
      phones: [],
      emails: [],

      codCountries: codCountries.map((c) => {
        return {
          ...c,
          dial_code: c.dial_code.substring(1),
        };
      }),
      loading: false,
      data: null,
      member: {
        ...TEMPLATE_MEMBER,
      },
      coOwner: {
        ...TEMPLATE_MEMBER,
      },
      contract: {
        ...TEMPLATE_CONTRACT,
      },

      cupons,
      typePaymentsExternal,
      priceFinal: 0,
      discountCupon: null,
      discountYears: null,
      priceOriginal: 0,

      newBeneficiario: {
        ContratoId: null,
        Nombre: "",
        ApellidoPaterno: "",
        Parentesco: 0,
        TipoSocio: "F",
        LadaTelefono: null,
        Telefono: null,

        /*
         * OPCIONAL
         */
        Email: null,
        Nacionalidad: null,
        EstadoCivil: null,
        Idioma: null,
        Password: null,
        Pais: null,
        Estado: null,
        Ciudad: null,
        Direccion: null,
        CodigoPostal: null,
        FechaNacimiento: null,
        LadaCelular: null,
        Celular: null,
        LadaOtro: null,
        OtroCelular: null,
      },
      numberOfWeek: {
        VipWeeks: "",
        SmartWeeks: "",
        LastMinuteWeeks: "",
      },
      formPayment: {
        Monto: 0,
        // ContratoId: null,
        FechaPago: null,
        TipoPago: null,
        Years: null,
        Referencia: null,
        AbonoInicial: null,
        // MontoTotalPagoDiferido: null,
      },
      club: {
        Nombre: null,
        Plantillas: [],
      },
      travelVoucher: [],
      beneficiaries: [],
      renovations: [],
      statusAccounts: [],
      paymentMethod: [],
      bitrix24: null,
      ifSyncBitrix: false,
      PrincipalPagador: "B",
      paymentsDeferred: [],

      activeTab: 0,
      isEdit: false,
      isCoEdit: false,
      listCatalogues,
      catalogues: {},
      selectedRow: null,
      loadingButtonEditStatusMembership: false,
      loadingButtonEditStatusContract: false,
      loadingButtonSendEmailMembershipStatus: false,
      loadingButtonRenewalPaymentLink: false,
      loadingResendPaymentLink: false,
      loadingCancelPaymentLink: false,
      loadingButtonSendPaymentExternal: false,
      loadingButtonSendPaymentDeferred: false,
      loadingButtonSendPaymentDeferred__step1: false,

      payLink: null,
      codeReset: false,
      agentEmail: null,
      usersStaff: [],
      // allPeriods: [],

      /**
       * Info
       */
      infoMembers: [
        {
          key: "Nombre",
          fieldIso: "name",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "ApellidoPaterno",
          fieldIso: "lastname_ext",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        // {
        //   key: "ApellidoMaterno",
        //   fieldIso: "mothersLastname",
        //   type: "text",
        //   size: 3,
        //   isInfo: false,
        // },
        {
          key: "Email",
          fieldIso: "email",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "FechaNacimiento",
          fieldIso: "birthdate",
          type: "date",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "LadaTelefono",
          fieldIso: "codPhone",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatPaises",
          optionsIsCatalogue: true,
          optionsFilters: (data) => {
            if (!data || data.length === 0) return [];
            let tmp = [...data];
            return tmp
              .reduce((acum, value) => {
                const exists = acum.find((a) => a.Lada === value.Lada);
                if (!exists) acum.push(value);
                return acum;
              }, [])
              .sort((a, b) => (a.Lada > b.Lada ? 1 : b.Lada > a.Lada ? -1 : 0));
          },
          withFilters: true,
          valueOption: "Lada",
          showOption: (tmp) => {
            return `+${tmp.Lada}`;
          },
          required: true,
        },
        {
          key: "Telefono",
          fieldIso: "phone",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "LadaCelular",
          fieldIso: "codCellphone",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatPaises",
          optionsIsCatalogue: true,
          optionsFilters: (data) => {
            if (!data || data.length === 0) return [];
            let tmp = [...data];
            return tmp
              .reduce((acum, value) => {
                const exists = acum.find((a) => a.Lada === value.Lada);
                if (!exists) acum.push(value);
                return acum;
              }, [])
              .sort((a, b) => (a.Lada > b.Lada ? 1 : b.Lada > a.Lada ? -1 : 0));
          },
          withFilters: true,
          valueOption: "Lada",
          showOption: (tmp) => {
            return `+${tmp.Lada}`;
          },
        },
        {
          key: "Celular",
          fieldIso: "cellphone",
          type: "text",
          size: 3,
          isInfo: false,
        },
        {
          key: "Pais",
          fieldIso: "country",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatPaises",
          optionsIsCatalogue: true,
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
          },
          required: true,
        },
        {
          key: "Estado",
          fieldIso: "state",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatEstadosPais",
          optionsIsCatalogue: true,
          valueOption: "Id",
          optionsFilters: (data) => {
            if (!data || data.length === 0) return [];
            else return data.filter((d) => d.PaisId === this.member.Pais);
          },
          withFilters: true,
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEng;
          },
          required: true,
        },
        {
          key: "Ciudad",
          fieldIso: "city",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "Direccion",
          fieldIso: "address",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "CodigoPostal",
          fieldIso: "zip",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "Nacionalidad",
          fieldIso: "nationality",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatPaises",
          optionsIsCatalogue: true,
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nacionalidad : tmp.NacionalidadEn;
          },
          required: true,
        },
        {
          key: "EstadoCivil",
          fieldIso: "civilStatus",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatEstadoCivil",
          optionsIsCatalogue: true,
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
          },
          required: true,
        },
        {
          key: "Idioma",
          fieldIso: "language",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatIdiomas",
          optionsIsCatalogue: true,
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
          },
          required: true,
        },
        // {
        //   key: "FechaRegistro",
        //   fieldIso: "registrationDate",
        //   type: "text",
        //   size: 3,
        //   isInfo: true,
        // },
      ],
      infoCoMembers: () => [
        ...this.infoMembers,
        // ...this.infoMembers.filter((t) =>
        //   [
        //     "Nombre",
        //     "ApellidoPaterno",
        //     "Email",
        //     "LadaTelefono",
        //     "Telefono",
        //   ].includes(t.key)
        // ),
      ],
      infoNumberOfWeek: [
        {
          key: "VipWeeks",
          fieldIso: "vipWeeks",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "SmartWeeks",
          fieldIso: "smartWeeks",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },

        {
          key: "LastMinuteWeeks",
          fieldIso: "lastMinuteWeeks",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
      ],
      infoBeneficiares: [
        {
          key: "Nombre",
          fieldIso: "name",
          type: "text",
          size: 3,
          isParse: false,
          isInfo: false,
          required: true,
        },
        {
          key: "ApellidoPaterno",
          fieldIso: "lastname",
          type: "text",
          isParse: false,
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "Parentesco",
          fieldIso: "relationship",
          type: "select",
          size: 3,
          isInfo: false,
          optionIsCatalogue: true,
          options: "CatParentescos",
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
          },
          required: true,
        },
      ],
      infoContract: [
        {
          key: "EstadoContrato",
          fieldIso: "contractStatus",
          section: "contrat",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatStatusContrato",
          optionsIsCatalogue: true,
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
          },
          required: true,
        },
        {
          key: "Idioma",
          fieldIso: "language",
          section: "contrat",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatIdiomas",
          optionsIsCatalogue: true,
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
          },
          required: true,
        },
        {
          key: "Numero",
          section: "contrat",
          fieldIso: "contractNumber",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "LoginID",
          fieldIso: "LoginID",
          section: "membresia",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "EstadoMembresia",
          fieldIso: "membershipStatus",
          section: "membresia",
          isParse: true,
          parse: (value) => {
            return value
              ? this.i18n.active[this.iso]
              : this.i18n.inactive[this.iso];
          },
        },
        {
          key: "FechaActivacion",
          type: "date",
          isInfo: false,
          fieldIso: "activationDate",
          section: "membresia",
          isParse: true,
          parse: (value) => {
            return this.parseDate(value);
          },
        },
        {
          key: "FechaRenovacion",
          type: "date",
          isInfo: false,
          fieldIso: "renovationDate",
          section: "membresia",
          isParse: true,
          /* parse: (value) => {
            return this.parseDate(value);
          }, */
          parse: (value) => {
            const now = Date.now();
            this.isExpired = value <= now;
            return this.isExpired
              ? this.parseDate(value)
              : this.parseDate(value);
          },
        },
        {
          key: "Years",
          section: "contrat",
          fieldIso: "years",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "Tarifa",
          fieldIso: "fee",
          section: "membresia",
          isParse: true,
          parse: (value, obj = []) => {
            return `$ ${value} ${obj.Moneda}`;
          },
        },
        {
          key: "Periodicidad",
          fieldIso: "period",
          section: "membresia",
          isParse: true,
          parse: (value) => {
            const cat = this.catalogues["CatPeriodicidad"];

            if (cat) {
              const rgx = new RegExp(value, "ig");
              const tmp = cat.find((c) => rgx.test(c.Id));
              if (tmp) return this.iso === "en" ? tmp?.NombreEn : tmp?.Nombre;
              else return "";
            } else return "";
          },
        },
        {
          key: "FechaRegistro",
          required: true,
          type: "text",
          isInfo: false,
          section: "contrat",
          fieldIso: "registrationDate",
          isParse: true,
          parse: (value) => {
            return this.parseDate(value);
          },
        },
        {
          key: "FechaCompra",
          section: "contrat",
          required: true,
          type: "text",
          isInfo: false,
          fieldIso: "purchaseDate",
          isParse: true,
          parse: (value) => {
            return this.parseDate(value);
          },
        },
        {
          key: "FechaProcesable",
          section: "contrat",
          required: true,
          type: "text",
          isInfo: false,
          fieldIso: "processableDate",
          isParse: true,
          parse: (value) => {
            return this.parseDate(value);
          },
        },
        {
          key: "FechaCancelado",
          section: "contrat",
          required: true,
          type: "text",
          isInfo: false,
          fieldIso: "cancellationDate",
          isParse: true,
          parse: (value) => {
            return this.parseDate(value);
          },
        },

        {
          key: "FechaExpiracion",
          required: true,
          section: "suscripcion",
          isInfo: false,
          type: "text",
          fieldIso: "subscription",
          isParse: true,
          /* parse: (value) => {
            return this.parseDate(value);
          }, */
          parse: (value) => {
            const now = Date.now();
            this.isExpired = value <= now;
            return this.isExpired
              ? this.i18n.expired[this.iso]
              : this.parseDate(value);
          },
        },
      ],
      infoVoucher: [
        {
          key: "Cantidad",
          fieldIso: "travelVoucher",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },

        {
          key: "Monto",
          fieldIso: "discountTravelVoucher",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
      ],

      missingFields: [],
    };
  },
  computed: {
    // totalAmount() {
    //   let t = this.contract.Tarifa * this.formPayment.Years;

    //   if (this.formPayment.Years === 99) t = this.contract.Tarifa * 10;

    //   this.formPayment.Monto = parseFloat(t.toFixed(2));
    //   return parseFloat(t.toFixed(2));
    //   // return `$ ${t.toFixed(2)} USD`;
    // },
    totalAmountPaymentLink() {
      let t = this.contract.Tarifa * this.yearsRenew;
      // this.priceOriginal = parseFloat(t.toFixed(2));
      // let d = 0
      // if (this.yearsRenew === 3) {
      //   t = t - t * (10 / 100);
      //   this.discountYears = parseFloat((t * (10 / 100)).toFixed(2));
      // }
      // if (this.yearsRenew === 5) {
      //   t = t - t * (20 / 100);
      //   this.discountYears = parseFloat((t * (20 / 100)).toFixed(2));
      // }

      if (this.yearsRenew === 99) t = this.contract.Tarifa * 10;

      this.priceOriginal = parseFloat(t.toFixed(2));

      if (this.cuponRenew) {
        const discount = this.cupons.find((c) => c.code === this.cuponRenew);
        if (discount) {
          t = t - t * (discount.discount / 100);
          this.discountCupon = parseFloat(
            (this.priceOriginal * (discount.discount / 100)).toFixed(2)
          );
        }
      }

      this.priceFinal = parseFloat(t.toFixed(2));

      return `$ ${this.priceFinal} USD`;
    },
    maxDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Los meses son indexados desde 0
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    sortedRenovations() {
      // return this.renovations.slice().sort((a, b) => {
      //   if (!a.FechaPago) return 1;
      //   if (!b.FechaPago) return -1;
      //   return new Date(b.FechaPago) - new Date(a.FechaPago);
      // });
      return this.renovations;
    },
    lastRenovationDate() {
      if (this.renovations && this.renovations.length > 0) {
        const lastRenovation = this.renovations[this.renovations.length - 1];
        return lastRenovation ? lastRenovation.FechaFinPeriodo : null;
      }
      return null;
    },
    periodOptions() {
      const periods = [];
      let dateA =
        this.contract?.FechaRenovacion || this.contract?.FechaRegistro;
      let YearsCondoned = 0;
      if (this.codeReset) {
        let newDateA = moment().valueOf();
        YearsCondoned = moment(newDateA).diff(moment(dateA), "years");
        dateA = newDateA;
      }

      for (let i = 1; i <= 10; i++) {
        let dateB = this.calculateNewExpirationDate(dateA, i);
        if (this.codeReset) dateB = this.calculateNewExpirationDate(dateA, i);
        periods.push({
          value: i,
          label: `${i} ${this.i18n.years[this.iso]} (${this.parseDate(
            dateA
          )} - ${this.parseDate(dateB)}) `, //${i === 3 ? "[10%]" : i === 5 ? "[20%]" : ""}
          showPeriodIfCodeReset: `${this.parseDate(dateA)} - ${this.parseDate(
            dateB
          )}`,
          YearsCondoned,
        });
      }
      periods.push({
        value: 99,
        label: NO_MORE_FEES, //[25%]
        YearsCondoned,
        showPeriodIfCodeReset: NO_MORE_FEES,
      });
      // this.allPeriods = periods;
      return periods;
    },
    /* addEmailOwner(){
      this.emails.push(this.newPhoneNumber);
    } */
  },
  async created() {
    if (!this.$route.query?.id) this.$router.push("/catcher-members");
    if (this.$route.query?.view === "contract") this.activeTab = 1;

    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    //UserId
    this.userId = localStorage.getItem("userId");

    //PerPage
    const perPageLocalStorage = localStorage.getItem("perPageReserves");
    if (!perPageLocalStorage) this.savePerPage();
    else this.perPage = perPageLocalStorage;

    //Permissions
    this.permissions = localStorage.getItem("role");

    //
    await this.getCatalogues();
    await this.getTypePayment();
    await this.getProfile();
    await this.allUsersStaff();
  },
  components: {
    CommentMember,
    ActionsMember,
    ContractMember,
  },
  mounted() {},
  methods: {
    async getProfile() {
      try {
        // this.loading = true;
        const { data } = await axios.get(
          `${API_URL}/catcher/members?idMongo=${this.$route.query?.id}`
        );
        this.data = data;
        await this.parseData(this.data);

        // console.log("member", this.member);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de socio",
          type: "is-danger",
        });
      }
    },
    allowEdit() {
      this.isEdit = true;
    },
    allowCoEdit() {
      this.isCoEdit = true;
    },
    cancelEdit() {
      this.isEdit = false;
      this.parseData(this.data);
    },
    cancelCoEdit() {
      this.isCoEdit = false;
    },
    async editProfile() {
      try {
        this.loading = true;
        await axios.put(`${API_URL}/catcher/members/edit/${this.memberId}`, {
          ...this.member,
        });
        this.cancelEdit();
        this.getProfile();
        this.loading = false;
        this.$buefy.toast.open({
          message: "Updated OK",
          type: "is-success",
        });
      } catch (err) {
        this.loading = false;
        console.log(err);
        let typeError = err?.response?.data?.typeError;
        if (typeError) {
          this.$buefy.toast.open({
            message:
              typeError?.message[this.iso] || "Error al editar datos de socio",
            type: "is-danger",
          });
        } else {
          this.$buefy.toast.open({
            message: "Error al editar datos de socio",
            type: "is-danger",
          });
        }
      }
    },
    async editFriendsAndFamily() {
      try {
        this.loading = true;
        for (const benef of this.beneficiaries) {
          try {
            await axios.put(`${API_URL}/catcher/members/edit/${benef._id}`, {
              Nombre: benef.Nombre,
              ApellidoPaterno: benef.ApellidoPaterno,
              Parentesco: benef.Parentesco,
            });
          } catch (err) {
            console.log(`Error al actualizar el beneficiario`, err);
            this.$buefy.toast.open({
              message: `Error al actualizar el beneficiario`,
              type: "is-danger",
            });
          }
        }
        this.cancelEdit();
        await this.getProfile();
        this.loading = false;
        this.$buefy.toast.open({
          message: "Updated OK",
          type: "is-success",
        });
      } catch (err) {
        this.loading = false;
        console.log(err);
        let typeError = err?.response?.data?.typeError;
        if (typeError) {
          this.$buefy.toast.open({
            message:
              typeError?.message[this.iso] ||
              "Error al editar datos de amigos y familia",
            type: "is-danger",
          });
        } else {
          this.$buefy.toast.open({
            message: "Error al editar datos de amigos y familia",
            type: "is-danger",
          });
        }
      }
    },
    async editCoProfile() {
      try {
        this.loading = true;
        await axios.put(`${API_URL}/catcher/members/edit/${this.coMemberId}`, {
          ...this.coOwner,
        });
        this.cancelEdit();
        this.getProfile();
        this.loading = false;
        this.$buefy.toast.open({
          message: "Updated OK",
          type: "is-success",
        });
      } catch (err) {
        this.loading = false;
        this.cancelCoEdit();
        console.log(err);
        let typeError = err?.response?.data?.typeError;
        if (typeError) {
          this.$buefy.toast.open({
            message:
              typeError?.message[this.iso] ||
              "Error al editar datos de co titular",
            type: "is-danger",
          });
        } else {
          this.$buefy.toast.open({
            message: "Error al editar datos de co titular",
            type: "is-danger",
          });
        }
      }
    },
    parseData(data) {
      if (data?.member) {
        this.memberId = data.member?._id;
        this.LoginID = data.member?.ContratoId?.LoginID;

        for (let i in this.member) {
          this.member[i] = data.member[i];
        }
        if (this.member.FechaNacimiento) {
          this.member.FechaNacimiento = new Date(this.member.FechaNacimiento);
        } else this.member.FechaNacimiento = null;

        if (data.member?.beneficiaries) {
          this.beneficiaries = data.member?.beneficiaries;
        }

        if (data.member?.numberOfWeeks) {
          this.numberOfWeek = data.member?.numberOfWeeks;
        }

        if (data.member?.detailsContract?.TravelVoucher) {
          this.travelVoucher = data.member?.detailsContract?.TravelVoucher;
        }

        if (data.member?.coOwner) {
          this.coMemberId = data.member?.coOwner._id;
          for (let i in this.coOwner) {
            this.coOwner[i] = data.member.coOwner[i];
          }
          if (this.coOwner.FechaNacimiento) {
            this.coOwner.FechaNacimiento = new Date(
              this.coOwner.FechaNacimiento
            );
          } else this.coOwner.FechaNacimiento = null;

          if (this.coOwner.FechaRegistro) {
            this.coOwner.FechaRegistro = moment(
              this.coOwner.FechaRegistro
            ).format("DD-MM-YYYY");
          } else this.coOwner.FechaRegistro = null;
        }

        if (data?.member?.ContratoId) {
          this.contract = data?.member?.ContratoId;

          this.club.Nombre = data.member.ContratoId?.ClubId?.Nombre || "";
          this.club.Plantillas = [];
          if (data.member.ContratoId?.ClubId?.Plantillas) {
            const Plantillas = data.member.ContratoId?.ClubId?.Plantillas;
            for (let i in Plantillas) {
              const doc = data.member?.files.find((f) => f.Referencia === i);
              const docPayload = {
                Referencia: i,
                Nombre: doc?.Nombre || null,
                FechaCreacion: doc?.FechaCreacion || null,
                Id: doc?.Id || null,
                CreadoPor: doc?.CreadoPor || null,
              };
              this.club.Plantillas.push(docPayload);
            }
          }
        }

        if (data?.member?.renovations) {
          this.renovations = data.member.renovations;
        }

        if (data?.member?.statusAccount) {
          this.statusAccounts = data.member.statusAccount;
        }

        if (data?.member?.bitrixFound) {
          this.bitrix24 = data.member.bitrixFound.map((b) => b.ID).join(", ");
        }

        if (
          !this.bitrix24 &&
          data?.member?.ContratoId?.EstadoMembresia &&
          data?.member?.ContratoId?.EstadoContrato === 2 &&
          data?.member?.ContratoId?.FechaRenovacion > Date.now()
        ) {
          this.ifSyncBitrix = true;
        }

        if (data?.member?.payLink) {
          this.payLink = data.member.payLink;
          const payLink = this.payLink;
          const payloadLink = {
            SolicitudId: "",
            ContratoId: this.member?.ContratoId?.Id,
            FechaPago: "",
            FechaInicioPeriodo:
              payLink?.DatosUtiles?.FechaInicioPeriodo ||
              this.contract?.FechaRenovacion ||
              this.contract?.Expiracion ||
              this.contract?.Registro,
            FechaFinPeriodo: payLink?.DatosUtiles?.FechaFinPeriodo || "",
            Anualidades: payLink?.AniosRenovacion || "",
            Cargo: payLink?.Monto || "",
            MemberId: payLink?.CreadoPorEmail || "",
            FechaEnvio: payLink?.FechaEnvio || "",
            ConfirmacionPago: "",
            isPayLink: true,
            PayLinkId: payLink?.Id,
          };
          this.renovations = [payloadLink, ...this.renovations];
        }

        if (
          data?.member?.paymentsDeferred &&
          data.member?.paymentsDeferred?.main
        ) {
          this.paymentsDeferred = [data.member?.paymentsDeferred];
        }
      }
    },
    async getCatalogues() {
      try {
        this.loading = true;

        const promises = [];

        for (let cat of listCatalogues) {
          promises.push(
            axios
              .get(`${API_URL}/catalogue/${cat}`)
              .then(({ data }) => {
                this.catalogues[cat] = data;
              })
              .catch((err) => {
                console.log(err);
                this.$buefy.toast.open({
                  message: "Error al consultar datos de catalogos",
                  type: "is-danger",
                });
              })
          );
          // const { data } = await axios.get(`${API_URL}/catalogue/${cat}`);
          // this.catalogues[cat] = data;
        }

        await Promise.all(promises);

        // console.log("catalogues", this.catalogues);
        // this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de catalogos",
          type: "is-danger",
        });
      }
    },

    parseDate(value) {
      moment.locale(this.iso);
      return value ? moment(value).utc().format("MMMM D, YYYY") : "";
    },

    emitGoLastComment() {
      this.$refs.commentmember.goLastToComment();
    },
    actionTab(tab) {
      if (tab === 3) this.emitGoLastComment();
    },
    /* renew() {
      this.$buefy.dialog.prompt({
        message: this.i18n.renew__message1[this.iso],
        confirmText: this.i18n.next[this.iso],
        inputAttrs: {
          type: "number",
          value: 1,
          min: 0,
          max: 99,
        },
        trapFocus: true,
      });
      this.$buefy.dialog.prompt({
        message: this.i18n.renew__message2[this.iso],
        confirmText: this.i18n.send[this.iso],
        inputAttrs: {
          maxlength: 10,
          required: false,
        },
        trapFocus: true,
        onConfirm: (cupon) => this.sendRenew(years, cupon),
      });
    }, */
    activateModal() {
      this.missingFields = [];
      for (const m of this.infoMembers) {
        if (!m.isInfo && !this.member[m.key]) {
          this.missingFields.push(this.i18n[m.fieldIso][this.iso]);
        }
      }
    },
    async createBenef() {
      this.newBeneficiario.ContratoId = this.contract.Id;
      try {
        await axios.post(`${API_URL}/catcher/members/create`, {
          ...this.newBeneficiario,
        });

        this.showModal = false;
        this.$buefy.toast.open({
          message: "OK",
          type: "is-success",
        });
        await this.getProfile();
      } catch (err) {
        this.$buefy.toast.open({
          message: "Error al renovar socio",
          type: "is-danger",
        });
      }
    },
    async sendRenew(qty, cupon) {
      try {
        this.loadingButtonRenewalPaymentLink = true;

        const cuponObject = this.cupons.find((c) => c.code === cupon);
        const { YearsCondoned } = this.periodOptions.find(
          (p) => p.value === this.yearsRenew
        );

        await axios.post(`${API_URL}/renewals/create?type=individual`, {
          LoginID: this.LoginID,
          qty,
          discountCode: cupon === "" ? null : cupon,
          agentEmail:
            this.agentEmail || localStorage.getItem("userEmail") || null,
          amount: this.priceFinal,
          dataUtil: {
            cupon: cuponObject,
            FechaExpiracion: this.calculateNewExpirationDate(
              this.contract?.FechaRenovacion || this.contract?.FechaRegistro,
              this.yearsRenew
            ),
            Years: this.yearsRenew,
            priceOriginal: this.priceOriginal,
            discountYears: this.discountYears,
            discountCupon: this.discountCupon,
            codeCupon: cupon,
            priceFinal: this.priceFinal,
            PrincipalPagador: this.PrincipalPagador,
            codeReset: this.codeReset,
            YearsCondoned,
          },
        });

        this.codeReset = false;
        this.agentEmail = null;
        this.codeReset = false;

        // this.renewalUrl = response.data.url;
        this.$buefy.toast.open({
          message: this.i18n.createPaymentsLinks__msgOk[this.iso],
          type: "is-success",
        });

        this.loadingButtonRenewalPaymentLink = false;
        this.showModal = false;
        this.getProfile();
      } catch (err) {
        console.log(err);
        this.loadingButtonRenewalPaymentLink = false;
        this.showModal = false;
        this.$buefy.toast.open({
          message: "Error al crear enlace de pago",
          type: "is-danger",
        });
      }
    },
    async editStatusMembership() {
      try {
        this.loadingButtonEditStatusMembership = true;
        await axios.put(
          `${API_URL}/catcher/edit-status-membership/contract/${
            this.contract.Id
          }?updatedBy=${localStorage.getItem("user")}`
        );
        // this.showModal = false;

        this.$buefy.toast.open({
          message: this.i18n.membershipActivatedOk[this.iso],
          type: "is-success",
        });
        await this.getProfile();
        await this.$bus.emit("refresh-comments");
      } catch (err) {
        console.log(err);
        this.showModal = false;
        this.$buefy.toast.open({
          message: "Error al editar contrato",
          type: "is-danger",
        });
      }
    },
    async editStatusContract() {
      try {
        await axios.put(
          `${API_URL}/catcher/edit-status/contract/${this.contract.Id}?status=${
            this.contract.EstadoContrato
          }&updatedBy=${localStorage.getItem("user")}`
        );
        this.showModal = false;
        this.$buefy.toast.open({
          message: "OK",
          type: "is-success",
        });
        await this.getProfile();
        await this.$bus.emit("refresh-comments");
      } catch (err) {
        console.log(err);
        this.showModal = false;
        this.$buefy.toast.open({
          message: "Error al editar contrato",
          type: "is-danger",
        });
      }
    },
    async editLenguage() {
      try {
        this.loading = true;
        await axios.put(
          `${API_URL}/catcher/contract/edit/${this.contract.Id}`,
          {
            Idioma: this.contract.Idioma,
          }
        );

        this.showModal = false;
        this.$buefy.toast.open({
          message: "OK",
          type: "is-success",
        });
        await this.getProfile();
      } catch (err) {
        console.log(err);
        this.showModal = false;
        this.$buefy.toast.open({
          message: "Error al editar contrato",
          type: "is-danger",
        });
      }
    },
    /* async sendEmailStatusMembership() {
      try {
        this.loadingButtonSendEmailMembershipStatus = true;
        await axios.put(
          `${API_URL}/catcher/edit-status-membership/contract/${this.contract.Id}?forceTrue=y`
        );
        this.loadingButtonSendEmailMembershipStatus = false;
        this.$buefy.toast.open({
          message: "OK",
          type: "is-success",
        });
        await this.getProfile();
      } catch (err) {
        console.log(err);
        this.loadingButtonSendEmailMembershipStatus = false;
        this.$buefy.toast.open({
          message: "Error al enviar email",
          type: "is-danger",
        });
      }
    }, */
    async getTypePayment() {
      try {
        const { data } = await axios.get(`${API_URL}/payment-type/list`);

        this.paymentMethod = data.response;
      } catch (err) {
        console.error("Error fetching payment types:", err);
      }
    },
    openModal(key) {
      if (key === "formulario") {
        this.modalType = "formulario";
      } else if (key === "activate") {
        this.modalType = "activate";
        this.activateModal();
      } else if (key === "EstadoContrato") {
        this.modalType = "contrato";
        this.newStatus = this.contract[key];
      } else if (key === "Idioma") {
        this.modalType = "idioma";
        this.newStatus = this.contract[key];
      } else if (key === "renovar") {
        this.modalType = "renovar";
      } else if (key === "addPayment") {
        this.modalType = "addPayment";
      } else if (key === "addPaymentDeferred") {
        this.modalType = "addPaymentDeferred";
      } else if (key === "addPaymentDeferred__step1") {
        this.modalType = "addPaymentDeferred__step1";
      } else if (key === "addPaymentDeferred__step2") {
        this.modalType = "addPaymentDeferred__step2";
      }
      this.showModal = true;
    },
    handleButtonStatus(newStatus) {
      if (!isNaN(newStatus)) {
        this.editStatusContract();
      } else {
        this.editLenguage();
      }
    },
    goPath(row) {
      const { ApellidoPaterno, Nombre, Email } = this.member;
      const pushPath = this.$router.resolve({
        path: "/details-renewals",
        query: {
          id: row,
          details: row.details,
          loginID: this.LoginID,
          apellidoPaterno: ApellidoPaterno,
          nombre: Nombre,
          email: Email,
        },
      });
      window.open(pushPath.href, "_blank");
    },
    toggleButtons() {
      this.showButtons = !this.showButtons;
    },
    calculateNewExpirationDate(startDateTimestamp, yearsToAdd) {
      let startDate = moment(startDateTimestamp);
      if (this.codeReset) startDate = moment();
      const endDate = startDate.add(yearsToAdd, "years");
      return endDate.valueOf();
    },
    handleButtonClick(option) {
      this.selectedOption = option;

      if (option === "copy" && this.renewalUrl) {
        this.copyToClipboard(this.renewalUrl);
        this.$buefy.toast.open({
          message: "Copied Link",
          type: "is-success",
        });
      }
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log("Texto copiado al portapapeles");
        })
        .catch((err) => {
          console.error("Error al copiar el texto: ", err);
        });
    },
    async sendPayment() {
      try {
        this.loadingButtonSendPaymentExternal = true;
        await axios.post(`${API_URL}/renewals/create-manual`, {
          // Monto: parseFloat(this.totalAmount),
          agentEmail:
            this.agentEmail || localStorage.getItem("userEmail") || null,
          ContratoId: this.contract.Id,
          codeReset: this.codeReset,
          ...this.formPayment,
        });

        this.$buefy.toast.open({
          message: this.i18n.externalPayment__msg1[this.iso],
          type: "is-success",
        });

        this.formPayment.Monto = 0
        this.formPayment.FechaPago = null
        this.formPayment.TipoPago = null
        this.formPayment.Years = null
        this.formPayment.Referencia = null
        this.formPayment.AbonoInicial = null
        // this.paymentsDeferred = []
        
        // await this.getProfile();
        window.location.reload();
        this.loadingButtonSendPaymentExternal = false;
        this.showModal = false;
      } catch (err) {
        console.log(err);
        this.loadingButtonSendPaymentExternal = false;
        this.$buefy.toast.open({
          message: this.i18n.externalPayment__msg2[this.iso],
          type: "is-danger",
        });
      }
    },
    async sendPaymentDeferred() {
      try {
        this.loadingButtonSendPaymentDeferred = true;
        const isPagoCompletado = this.paymentsDeferred && this.paymentsDeferred[0] ? this.paymentsDeferred[0].main.missingAmount - this.formPayment.AbonoInicial : null
        await axios.post(`${API_URL}/payment/manual/create-deferred`, {
          agentEmail:
            this.agentEmail || localStorage.getItem("userEmail") || null,
          ContratoId: this.contract.Id,
          codeReset: this.codeReset,
          ...this.formPayment,
          AbonoPrincipal: this.paymentsDeferred && this.paymentsDeferred[0] ? this.paymentsDeferred[0].main : null,
          PagoCompletado: isPagoCompletado === 0 ? true : false,
        });

        this.$buefy.toast.open({
          message: this.i18n.addPaymentDeferred__msg1[this.iso],
          type: "is-success",
        });

        this.formPayment.Monto = 0
        this.formPayment.FechaPago = null
        this.formPayment.TipoPago = null
        this.formPayment.Years = null
        this.formPayment.Referencia = null
        this.formPayment.AbonoInicial = null
        // this.paymentsDeferred = []

        // await this.getProfile();
        window.location.reload();
        this.loadingButtonSendPaymentDeferred = false;
        this.showModal = false;
      } catch (err) {
        console.log(err);
        this.loadingButtonSendPaymentDeferred = false;
        this.$buefy.toast.open({
          message: this.i18n.addPaymentDeferred__msg2[this.iso],
          type: "is-danger",
        });
      }
    },
    async sendPaymentDeferred__step1() {
      try {
        this.loadingButtonSendPaymentDeferred__step1 = true;
        await axios.post(`${API_URL}/payment/manual/create-deferred/add`, {
          agentEmail:
            this.agentEmail || localStorage.getItem("userEmail") || null,
          ContratoId: this.contract.Id,
          ...this.formPayment,
        });

        this.$buefy.toast.open({
          message: this.i18n.addPaymentDeferred__msg1[this.iso],
          type: "is-success",
        });

        this.formPayment.Monto = 0
        this.formPayment.FechaPago = null
        this.formPayment.TipoPago = null
        this.formPayment.Years = null
        this.formPayment.Referencia = null
        this.formPayment.AbonoInicial = null

        await this.getProfile();
        this.loadingButtonSendPaymentDeferred__step1 = false;
        this.showModal = false;
      } catch (err) {
        console.log(err);
        this.loadingButtonSendPaymentDeferred__step1 = false;
        this.$buefy.toast.open({
          message: this.i18n.addPaymentDeferred__msg2[this.iso],
          type: "is-danger",
        });
      }
    },
    formattedAnnualPlan(item) {
      const { i18n, iso, contract } = this;
      const nextInvoice = i18n.nextInvoice[iso];
      const tarifa = contract.Tarifa;
      const forText = i18n.for[iso];
      const value = item.isParse
        ? item.parse(contract[item.key], contract)
        : contract[item.key];

      return `${nextInvoice} ${value} ${forText} $${tarifa} USD`;
    },
    addNumber() {
      if (this.newPhoneNumber) {
        this.phones.push(this.newPhoneNumber);
        this.newPhoneNumber = "";
      }
    },
    removeNumber(index) {
      this.phones.splice(index, 1);
    },
    addEmails() {
      if (this.newEmail) {
        this.emails.push(this.newEmail);
        this.newEmail = "";
      }
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    async editFF(IdFF, idx) {
      try {
        if (this.beneficiaries[idx].edit) {
          this.beneficiaries[idx].loading = true;
          this.$forceUpdate();
          await axios.put(`${API_URL}/catcher/members/edit/${IdFF}`, {
            Nombre: this.beneficiaries[idx].Nombre,
            ApellidoPaterno: this.beneficiaries[idx].ApellidoPaterno,
            Parentesco: this.beneficiaries[idx].Parentesco,
          });
          await this.getProfile();
          this.beneficiaries[idx].edit = false;
          this.beneficiaries[idx].loading = false;
          this.$buefy.toast.open({
            message: "OK",
            type: "is-success",
          });
        } else {
          this.beneficiaries[idx].edit = true;
          this.$forceUpdate();
        }
      } catch (err) {
        console.log(err);
        this.beneficiaries[idx].loading = false;
        this.$buefy.toast.open({
          message: "Error al actualizar beneficiario",
          type: "is-danger",
        });
      }
    },
    async createFF(idx) {
      try {
        this.beneficiaries[idx].loading = true;

        if (
          !this.beneficiaries[idx].Nombre ||
          !this.beneficiaries[idx].ApellidoPaterno ||
          !this.beneficiaries[idx].Parentesco
        ) {
          this.$buefy.dialog.alert({
            title: this.i18n.validationError[this.iso],
            message: this.i18n.allFieldsAreRequired[this.iso],
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          this.beneficiaries[idx].loading = false;
          return;
        }

        const payload = {
          ContratoId: this.contract.Id,
          Nombre: this.beneficiaries[idx].Nombre,
          ApellidoPaterno: this.beneficiaries[idx].ApellidoPaterno,
          Parentesco: this.beneficiaries[idx].Parentesco,
          Seal: this.SEAL,
          Idioma: this.contract.Idioma,
          TipoSocio: "F",
          Password: this.contract.LoginID,
        };

        await axios.post(`${API_URL}/catcher/members/create`, payload);

        this.beneficiaries[idx].edit = false;
        await this.getProfile();
        this.beneficiaries[idx].loading = false;
        this.$forceUpdate();
      } catch (err) {
        this.beneficiaries[idx].loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al crear beneficiario",
          type: "is-danger",
        });
      }
    },
    cancelEditFF(idx) {
      this.beneficiaries[idx].edit = false;
      this.$forceUpdate();
    },
    activateMembership() {
      const fieldsRequiered = this.infoMembers.filter((f) => f.required);
      let missingFields = [];
      for (let field of fieldsRequiered) {
        if (!this.member[field.key] || this.member[field.key] === "")
          missingFields.push(field.fieldIso);
      }

      missingFields = missingFields
        .map((f) => `<b>${this.i18n[f][this.iso]}</b>`)
        .join(", ");

      if (missingFields.length > 0) {
        this.$buefy.dialog.confirm({
          title: this.i18n.confirmActivation[this.iso],
          message: `${this.i18n.fieldsIncomplete[this.iso]} (${missingFields})`,
          confirmText: this.i18n.activate[this.iso],
          cancelText: this.i18n.cancel[this.iso],
          type: "is-warning",
          hasIcon: true,
          onConfirm: () => this.editStatusMembership(),
        });
      } else {
        this.$buefy.dialog.confirm({
          title: this.i18n.confirmActivation[this.iso],
          message: `${this.i18n.fieldsComplete[this.iso]}`,
          confirmText: this.i18n.activate[this.iso],
          cancelText: this.i18n.cancel[this.iso],
          type: "is-info",
          hasIcon: true,
          onConfirm: () => this.editStatusMembership(),
        });
      }
    },
    copyPaymentLink(Id) {
      navigator.clipboard.writeText(
        `${process.env.VUE_APP_SHORT_URL_PAYMENTLINK}/${Id}`
      );
      this.$buefy.toast.open({
        message: this.i18n.copyPaymentLink__msg1[this.iso],
        type: "is-success",
      });
    },
    async reSendPaymentLink(Id) {
      try {
        this.loadingResendPaymentLink = true;
        await axios.get(`${API_URL}/renewals/resend-vaucher/${Id}`);

        this.$buefy.toast.open({
          message: this.i18n.sendLinkPayment__msg1[this.iso],
          type: "is-success",
        });
        this.loadingResendPaymentLink = false;
      } catch (err) {
        this.loadingResendPaymentLink = false;
        console.log(err);
        this.$buefy.toast.open({
          message: this.i18n.sendLinkPayment__msg2[this.iso],
          type: "is-danger",
        });
      }
    },
    async cancelPaymentLink(Id) {
      try {
        this.loadingCancelPaymentLink = true;
        await axios.delete(`${API_URL}/renewals/cancel/${Id}`);

        this.$buefy.toast.open({
          message: this.i18n.cancelLinkPayment__msg1[this.iso],
          type: "is-success",
        });
        this.loadingCancelPaymentLink = false;
        this.payLink = null;
        await this.getProfile();
      } catch (err) {
        this.loadingCancelPaymentLink = false;
        console.log(err);
        this.$buefy.toast.open({
          message: this.i18n.cancelLinkPayment__msg2[this.iso],
          type: "is-danger",
        });
      }
    },
    onChangeYears(value) {
      console.log(value);
      let t = this.contract.Tarifa * this.formPayment.Years;
      if (this.formPayment.Years === 99) t = this.contract.Tarifa * 10;
      this.formPayment.Monto = parseFloat(t.toFixed(2));
    },
    async allUsersStaff() {
      try {
        // this.loading = true;
        const { data } = await axios.post(`${API_URL}/system/list-staff`, {});
        this.usersStaff = data?.users || [];
        // this.loading = false;
        // this.$buefy.toast.open({
        //   message: "Updated OK",
        //   type: "is-success",
        // });
      } catch (err) {
        // this.loading = false;
        console.log(err);
      }
    },
    async editAccesoTemporal() {
      try {
        // this.loading = true;
        await axios.put(
          `${API_URL}/catcher/contract/edit/${this.contract.Id}`,
          {
            AccesoTemporal: this.contract.AccesoTemporal,
          }
        );

        this.$buefy.toast.open({
          message: this.i18n.webTemporary__msg1[this.iso],
          type: "is-success",
        });
        // await this.getProfile();
      } catch (err) {
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al editar contrato",
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style scoped>
.articles {
  margin: 5rem 0;
  margin-top: -200px;
}

.articles .content p {
  line-height: 1.9;
  margin: 15px 0;
}

.author-image {
  position: absolute;
  top: -30px;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  border: 3px solid #ccc;
  border-radius: 50%;
}

.media-center {
  display: block;
  margin-bottom: 1rem;
}

.media-content {
  margin-top: 3rem;
}

.article,
.promo-block {
  margin-top: 6rem;
}

/* div.column.is-8:first-child {
  padding-top: 0;
  margin-top: 0;
} */
.article-title {
  font-size: 2rem;
  font-weight: lighter;
  line-height: 2;
}

.article-subtitle {
  color: #909aa0;
  margin-bottom: 3rem;
}

.article-body {
  line-height: 1.4;
  margin: 0 6rem;
}

.promo-block .container {
  margin: 1rem 5rem;
}

.btns {
  width: 200px;
}

/* .columns {
  display: flex;
  justify-content: flex-end;
} */
.row {
  display: flex;
  width: 53%;
  justify-content: flex-end;
}

.row-friendsfamily {
  gap: 1em;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 10px;
}

.row-friendsfamily-2 {
  gap: 1em;
  display: flex;
  width: 3%;
  justify-content: flex-end;
}

.column-content {
  display: flex;
  flex-direction: column;
}
.row-content {
  display: flex;
  width: 100%;
  gap: 1rem;
  margin-bottom: 15px;
}

.row-content-benef {
  display: flex;
  width: 80%;
  margin-bottom: 3px;
}

.field-text {
  display: block;
  width: 100%;
  height: 39px;
  padding: 0.9em;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #ff000042;
  font-size: 1em;
  line-height: 1.5;
  color: #495057;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* .column-content {
  display: flex;
  flex-direction: column;
} */
.content-estatus {
  background-color: aqua;
}

.btn-active {
  width: 200px;
}

.btn-inactive {
  width: 200px;
  background-color: #ff000042;
}

.short-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  cursor: pointer;
  display: inline-block;
}

.short-text:hover {
  overflow: visible;
  z-index: 1000;
  max-width: none;
}

.buttons {
  display: flex;
  justify-content: center;
}

.button-wrapper {
  display: flex;
  align-items: baseline;
  margin: 5px;
  flex-direction: row;
  justify-content: space-between;
}

.label {
  margin-left: 10px;
}

.truncated-url {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.renewUrl {
  width: 270px;
  background-color: #dffaf0;
  border-radius: 10px;
  margin: auto;
  padding: 5px;
}
</style>

<style>
tr.is-selected-row {
  background: #7eb0e9;
  color: #fff;
}

.custom-icon {
  background-color: #48c774;
  border-radius: 10px;
  color: white;
  padding: 15px;
  margin-right: 10px;
}

tr.is-info {
  background: #fdffc2;
  color: #35374b;
}
</style>
